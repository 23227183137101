import React, { useContext, useEffect, useState } from 'react';
import { PanelHeader } from 'components/panel';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import { ROUTE_SETTINGS } from 'routes/RouteList';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import {
  PanelSubtitle,
  PanelTitle,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import PanelBody from 'components/panel/PanelBody';
import Card from 'components/card/Card';
import CardHeader from 'components/card/card-header/CardHeader';
import { CardHeaderText, CardHeaderTitle } from 'components/card/card-header';
import CardBody from 'components/card/CardBody';
import Button from 'components/button/Button';
import Panel from 'components/panel/Panel';
import { useHistory } from 'react-router-dom';
import FieldInput from 'components/input/FieldInput';
import Icon from 'components/icon/Icon';
import { CardBodyTools } from 'components/card';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_USER } from 'services/aws/user-query';
import Loader from 'components/loader/Loader';
import { List } from 'components/list';
import { Table, TableBody, TableCell, TableRow } from 'components/table';
import { ListItem } from 'components/list/list-item';
import { StoreContext } from 'index';

const SearchUsers = () => {
  const { authStore } = useContext(StoreContext);
  const { push } = useHistory();
  const [search, setSearch] = useState('');
  const [result, setResult] = useState([]);

  const [searchUsers, { called, loading, data }] = useLazyQuery(SEARCH_USER, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data?.getCognitoUserEntities) {
      console.log(data.getCognitoUserEntities);
      const resultData = JSON.parse(data.getCognitoUserEntities);

      console.log(resultData);
      setResult(resultData);
    }
  }, [data]);

  const onSearch = () => {
    if (!search) return;
    if (search.length < 3) return;
    const searchValue = search.trim().toLowerCase();
    searchUsers({ variables: { search: searchValue } });
  };

  const openOrg = id => {
    authStore.switchEntityId(id);
  };

  return (
    <Panel>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb onClick={() => push(ROUTE_SETTINGS)}>
            <FormattedMessage {...messages.breadcrumbSettingsSearchUser} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.settingsSearchUserTitle} />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.settingsSearchUserSubtitle} />
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <Card secondary>
          <CardHeader secondary>
            <CardHeaderText>
              <CardHeaderTitle>
                <FormattedMessage {...messages.settingsSearchUserInputTitle} />
              </CardHeaderTitle>
            </CardHeaderText>
          </CardHeader>
          <CardBody secondary>
            <CardBodyTools extraClassNames="c-panel__tools-separated u-padding-none u-padding-bottom-large">
              <FieldInput
                rounded
                icon="search-grey"
                iconLeft
                onChange={e => setSearch(e.target.value)}
                onKeyEnter={onSearch}
              />
              <Button rounded light onClick={onSearch}>
                <Icon id="search-grey" strokeColor="color-neutral-dark" />
                <FormattedMessage {...messages.settingsSearchUsersLabel} />
              </Button>
            </CardBodyTools>
            {loading && <Loader />}
            <div>
              {called && result && (
                <List>
                  {result.map((user, index) => (
                    <ListItem key={`${user.id}_${index}`}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>User:</TableCell>
                            <TableCell>
                              <strong>
                                {user.firstname} {user.lastname}
                              </strong>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Email:</TableCell>
                            <TableCell>
                              <a href={`mailto:${user.email}`}>{user.email}</a>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ verticalAlign: 'top' }}>
                              Organisations:
                            </TableCell>
                            <TableCell>
                              {user.entities.map(entity => (
                                <Table key={`${user.id}_${entity.id}`}>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell colSpan={2}>
                                        <a
                                          href="#"
                                          onClick={() => openOrg(entity.id)}
                                        >
                                          <strong>{entity.name}</strong>
                                        </a>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>ID</TableCell>
                                      <TableCell>{entity.id}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>Parent ID</TableCell>
                                      <TableCell>{entity.parentId}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        style={{ verticalAlign: 'top' }}
                                      >
                                        Roles
                                      </TableCell>
                                      <TableCell>
                                        <RolesList roles={entity.roles} />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        style={{ verticalAlign: 'top' }}
                                      >
                                        Sub Organisation tree
                                      </TableCell>
                                      <TableCell>
                                        {entity?.subEntities?.length > 0 ? (
                                          <EntityList
                                            subEntities={entity.subEntities}
                                          />
                                        ) : (
                                          <span>None</span>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              ))}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          </CardBody>
        </Card>
      </PanelBody>
    </Panel>
  );
};

const RolesList = ({ roles }) => {
  const roleName = role => {
    switch (role) {
      case 'ROLE_ADMIN':
        return 'Administrator';
      case 'ROLE_SUBADMIN':
        return 'User administrator';
      case 'ROLE_REPORTER':
        return 'Reporter';
      case 'ROLE_TESTER':
        return 'Tester';
      case 'ROLE_TESTDATA':
        return 'Testdata';
      default:
        return role;
    }
  };
  return (
    <ul>
      {roles.map(role => (
        <li key={role}>
          <strong>{roleName(role)}</strong>
        </li>
      ))}
    </ul>
  );
};

const EntityList = ({ subEntities }) => {
  const { authStore } = useContext(StoreContext);

  const openOrg = id => {
    authStore.switchEntityId(id);
  };

  return (
    <ul>
      {subEntities.map(entity => (
        <li key={entity.id}>
          <a href="#" onClick={() => openOrg(entity.id)}>
            {entity.name}
          </a>
          {entity.subEntities && entity.subEntities.length > 0 && (
            <EntityList subEntities={entity.subEntities} />
          )}
        </li>
      ))}
    </ul>
  );
};

export default SearchUsers;
