import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import './Carousel.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const Carousel = ({ children, settings, ...props }) => {
  // const {} = props
  const [hasSetPosition, setHasSetPosition] = useState(false);
  const slider = useRef();
  const initialSlidePosition = settings.initialSlide;

  useEffect(() => {
    if (slider.current && !hasSetPosition) {
      slider.current?.slickGoTo(initialSlidePosition);
      setHasSetPosition(true);
    }
  }, [initialSlidePosition, hasSetPosition, slider]);

  return (
    <div className="c-carousel" style={{ maxWidth: '100%' }}>
      <Slider ref={slider} {...settings}>
        {children}
      </Slider>
    </div>
  );
};
