import { useContext } from 'react';
import { ResponsiveLine } from '@nivo/line';
import ProgressBar from 'components/progress-bar/ProgressBar';
import { getProgressBarColorByPercentile } from 'utils/calculation';
import {
  CHART_COLORS,
  CHART_COLORS_BORDER,
  CHART_TYPE,
  ProgressBarValueTypes
} from 'constants.js';
import { Card, CardBody } from 'components/card';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import ProgressBarGroup from 'components/progress-bar/ProgressBarGroup';
import ProgressBarLabelBlock from 'components/progress-bar/ProgressBarLabelBlock';
import { getResultLabel, getPercentageOfResult } from 'utils/benchmark';
import { filterDuplicateDates, getLocalDateFormat } from 'utils/date';
import GrowthPredictionTableCell from 'containers/pages/growthprediction/table/GrowthPredictionTableCell';

import messages from 'messages';
import { useIntl } from 'react-intl';
import { isNumber } from 'utils/math';
import { getAppLocale } from 'utils/locale';
import { StoreContext } from 'index';

const LineChart = ({ intl, title, tooltipSubTitle, chartData }) => {
  const avg = chartData[0].avg;
  const curve = 'monotoneX';
  const data = chartData;
  const benchmarkType = chartData[0].benchmarkType;
  const yValues = data.flatMap(item => item.data.map(entry => entry.y));

  const minValue = Math.min(...yValues);
  const maxValue = Math.max(...yValues);
  const min = minValue - (maxValue - minValue) / 5 - 1;
  const max = maxValue + (maxValue - minValue) / 5 + 1;

  const typeSelectWithOptions = data[0].data.some(
    obj => obj.inputType === 'select_with_options'
  );

  /*
  Deze berekening leek me onnodig ingewikkeld. Ik heb het vereenvoudigd. Maar hou de oude code nog even bij ter referentie.
  // linear, basis, cardinal, catmullRom, monotoneX, monotoneY, natural
  const [recalculatedMin, setRecalculatedMin] = useState(min);
  const [recalculatedMax, setRecalculatedMax] = useState(max);

  useEffect(() => {
    if (data) {
      const minValue = Array.isArray(min) ? min[0] : min;
      const maxValue = Array.isArray(max) ? max[0] : max;
      let newMin = minValue - (maxValue - minValue) / 10;
      let newMax = maxValue + (maxValue - minValue) / 10;

      /* In order to determine the nivo's yScale we will check some things *
      if (typeSelectWithOptions) {
        /* 1. inputType is 'select_with_options'
         ** we will set the line graph flat. Min = 0 and Max = 0 *
        setRecalculatedMin(0);
        setRecalculatedMax(0);
      } else {
        /* 3. inputType is OTHER THAN 'select_with_options' *
        if (data[0].data.length > 1) {
          /* 3.1. When there is more than 1 session:
           ** we'll set the min and the max accordingly *
          const minInArray = Math.min(...data[0].data.map(obj => obj.y));
          const maxInArray = Math.max(...data[0].data.map(obj => obj.y));

          if (newMin === newMax) {
            /* 3.1.1. This is an old check. Not sure where are these data coming from
             ** but so is the nivo in the beginning setted up. It stills works then
             ** taking now the min and max of the whole array*
            setRecalculatedMin(minInArray - maxInArray / 10);
            setRecalculatedMax(minInArray + maxInArray / 10);
          } else if (minInArray === maxInArray) {
            /* 3.1.2. If the first check is false, it will check if the min and max
             ** are the same on the whole array, and in this case will just add a
             ** bit of marge up and down the yScale *
            setRecalculatedMin(maxInArray * 0.95);
            setRecalculatedMax(maxInArray * 1.05);
          } else {
            /* 3.1.3. Lastly, for al the rest of the cases *
            setRecalculatedMin(minInArray - (maxInArray - minInArray) / 5);
            setRecalculatedMax(maxInArray + (maxInArray - minInArray) / 5);
          }
        } else {
          /* 3.2. When there is only 1 session *
          const value = parseFloat(data[0].data[0].y);

          if (value > 0) {
            setRecalculatedMin(value * 0.95);
            setRecalculatedMax(value * 1.05);
          } else if (value < 0) {
            setRecalculatedMin(value * 1.05);
            setRecalculatedMax(value * 0.95);
          } else {
            setRecalculatedMin(value + 2);
            setRecalculatedMax(value - 2);
          }
        }
      }
    }
  }, [data, min, max]);
*/

  return (
    <NormalLineChart
      data={data}
      min={min}
      max={max}
      avg={avg}
      curve={curve}
      title={title}
      tooltipSubTitle={tooltipSubTitle}
      intl={intl}
      benchmarkType={benchmarkType}
      isSelectWithOptions={typeSelectWithOptions}
    />
  );
};

const NormalLineChart = ({
  data,
  min,
  max,
  avg,
  curve,
  title,
  tooltipSubTitle,
  intl,
  benchmarkType,
  isSelectWithOptions
}) => {
  const {
    uiState: { locale }
  } = useContext(StoreContext);

  const tooltip = tooltipSubTitle
    ? slice => (
        <LineChartPopup
          avg={avg}
          slice={slice}
          title={title}
          subTitle={tooltipSubTitle}
          data={data}
          chartType={data[0].chartType}
          intl={intl}
          benchmarkType={benchmarkType}
        />
      )
    : null;

  /*let tickDates = data[0].data.map(obj =>
    obj.hasOwnProperty('sortDate') ? new Date(obj.sortDate) : new Date(obj.x)
  );*/

  const tickDates = data.flatMap(item =>
    item.data.map(obj =>
      obj.hasOwnProperty('sortDate') ? new Date(obj.sortDate) : new Date(obj.x)
    )
  );

  const filteredTickDates = filterDuplicateDates(tickDates);

  const DATE_FORMAT = '%Y-%m-%d';

  let dataRegrouped = [];
  if (isSelectWithOptions) {
    const groupedByAnswer = data[0].data.reduce(
      (map, e) => ({
        ...map,
        [e.result.value]: [...(map[e.result.value] ?? []), e]
      }),
      {}
    );

    for (const key in groupedByAnswer) {
      if (groupedByAnswer.hasOwnProperty(key)) {
        const element = groupedByAnswer[key];
        dataRegrouped.push({
          id: key,
          data: element
        });
      }
    }
  }

  // TODO: remove! This is a temp property.
  // If the PR in NIVO's github is merged, we will be able
  // to click on slices, which is for our use case beter. Because:
  // 1. autopositioning of sliceTooltip
  // 2. vertical clickable area (slice X)
  // For now, we will use the old MESH system, in order to add a
  // click() function pointing to the session on click.
  // PR #1 > https://github.com/plouc/nivo/pull/2187
  // PR #2 > https://github.com/plouc/nivo/pull/2128
  const usingSlice = true;

  return (
    // @ts-ignore
    <ResponsiveLine
      data={isSelectWithOptions ? dataRegrouped : data}
      enableGridX={false}
      sliceTooltip={usingSlice ? tooltip : undefined}
      enableSlices={usingSlice ? 'x' : false}
      tooltip={usingSlice ? undefined : tooltip}
      useMesh={!usingSlice}
      //   debugMesh={!usingSlice}
      //   debugSlices={usingSlice}
      //   onClick={(point, event) => {
      //       PUSH url > point.url
      //   }}
      curve={curve}
      margin={{
        top: 32,
        right: 32,
        bottom: 100,
        left: isSelectWithOptions ? 32 : 48
      }}
      xScale={{
        type: 'time',
        format: DATE_FORMAT,
        precision: 'hour'
      }}
      xFormat={`time:${DATE_FORMAT}`}
      axisBottom={{
        orient: 'top',
        tickSize: 5, // lineY
        tickPadding: 10, // textY = tickPadding + tickSize
        tickRotation: -65,
        legend: '',
        legendOffset: 36,
        legendPosition: 'middle',
        //   format: '%b %d, %Y',
        format: value => {
          return value.toLocaleDateString(getAppLocale(locale), {
            // you can use undefined as first argument
            year: 'numeric',
            month: 'short',
            day: '2-digit'
          });
        },
        tickValues: filteredTickDates
      }}
      yScale={yScale(min, max)}
      axisLeft={isSelectWithOptions ? null : axisLeft}
      enableCrosshair={true}
      crosshairType={'x'}
      pointSize={12}
      pointBorderWidth={2}
      pointBorderColor={e => {
        const getColor = Object.keys(CHART_COLORS).find(
          key => CHART_COLORS[key] === e.color
        );
        return CHART_COLORS_BORDER[getColor];
      }}
      isInteractive={true}
      colors={Object.values(CHART_COLORS)}
      theme={{
        tooltip: {
          container: {
            padding: 0,
            boxShadow: 'none'
          }
        }
      }}
    />
  );
};

export default LineChart;

const LineChartPopup = ({
  slice,
  title,
  subTitle,
  chartType,
  benchmarkType,
  avg,
  data
}) => {
  const intl = useIntl();
  const {
    uiState: { locale }
  } = useContext(StoreContext);
  const growthData = [];

  const slicePoints = slice.hasOwnProperty('slice')
    ? slice.slice.points
    : [slice.point];

  const date = slicePoints[0].data.date;
  const sortDate = slicePoints[0].data.sortDate;
  const unit = slicePoints[0].data.unit;
  const decimals = slicePoints[0].data.decimals;

  const parsedPercentiles = [];
  const results = [];

  let avgScores = [];
  let minScores = [];
  let maxScores = [];

  slicePoints.forEach(point => {
    const { percentile, result, unit, laterality } = point.data;

    if (chartType === CHART_TYPE.GROWTHTRACKER) {
      growthData.push({
        range: point.data.range,
        value: point.data.value,
        unit:
          point.data.unit + intl.formatMessage(messages.growthPredictionYear)
      });
    }

    if (percentile) {
      parsedPercentiles.push(
        isNumber(percentile) && percentile >= 0 && percentile <= 1
          ? (percentile * 100).toFixed(2)
          : percentile
      );
    }

    const resultLabel = getResultLabel(result, unit, decimals, laterality);

    results.push(resultLabel ? resultLabel : result);

    if (benchmarkType === 'average') {
      const arrOfFloats = data[0].data.map(obj => {
        return typeof obj.result === 'object'
          ? parseFloat(obj.result.value)
          : parseFloat(obj.result);
      });
      avgScores.push(
        arrOfFloats.reduce((a, b) => a + b, 0) / arrOfFloats.length
      );
      minScores.push(Math.min(...arrOfFloats));
      maxScores.push(Math.max(...arrOfFloats));
    }
  });

  // const { date, sortDate, percentile, result, unit, decimals } = sliceData;

  /*if (chartType === CHART_TYPE.GROWTHTRACKER) {
    growthData = {
      range: sliceData.range,
      value: sliceData.value,
      unit: sliceData.unit + intl.formatMessage(messages.growthPredictionYear)
    };
  }*/

  /*let parsedPercentile =
    isNumber(percentile) && percentile >= 0 && percentile <= 1
      ? (percentile * 100).toFixed(2)
      : percentile;

  let avgScore;
  let minScore;
  let maxScore;

  if (benchmarkType === 'average') {
    const arrOfFloats = data[0].data.map(obj => {
      return typeof obj.result === 'object'
        ? parseFloat(obj.result.value)
        : parseFloat(obj.result);
    });
    avgScore = arrOfFloats.reduce((a, b) => a + b, 0) / arrOfFloats.length;
    minScore = Math.min(...arrOfFloats);
    maxScore = Math.max(...arrOfFloats);
  }*/

  return (
    <Card tooltip extraClassNames="u-margin-bottom-small">
      <CardHeader secondary>
        <CardHeaderText extraClassNames="u-margin-none">
          <CardHeaderTitle>{title}</CardHeaderTitle>
          {date && !sortDate && (
            <CardHeaderSubtitle>{getLocalDateFormat(date)}</CardHeaderSubtitle>
          )}

          {sortDate && (
            <CardHeaderSubtitle>
              {getLocalDateFormat(sortDate)}
            </CardHeaderSubtitle>
          )}
        </CardHeaderText>
        <CardHeaderText extraClassNames="u-margin-none">
          <CardHeaderSubtitle>{subTitle}</CardHeaderSubtitle>
        </CardHeaderText>
      </CardHeader>
      {slicePoints.map((point, i) => (
        <CardBody key={point.id}>
          {chartType === CHART_TYPE.GROWTHTRACKER ? (
            <GrowthPredictionTableCell inTooltip data={growthData} />
          ) : (
            <>
              {benchmarkType !== 'average' &&
                (isNumber(parsedPercentiles[i]) &&
                parsedPercentiles[i] !== null ? (
                  <ProgressBarGroup fullWidth>
                    <ProgressBarLabelBlock value={results[i]} />
                    <ProgressBar
                      benchmarksWithFrame
                      range={getProgressBarColorByPercentile(
                        parsedPercentiles[i]
                      )}
                      value={`${parsedPercentiles[i]}`}
                    />
                  </ProgressBarGroup>
                ) : results[i].length > 0 ? (
                  <ProgressBarLabelBlock value={results[i]}>
                    <span
                      style={{
                        backgroundColor: point?.serieColor,
                        width: '20px',
                        height: '20px',
                        display: 'inline-block',
                        margin: '-5px 5px -5px 0',
                        borderRadius: '15%'
                      }}
                    ></span>
                  </ProgressBarLabelBlock>
                ) : (
                  <b>{results[i].label}</b>
                ))}
              {benchmarkType === 'average' &&
                (results[i].length > 0 ? (
                  <>
                    <b>{results[i]}</b>
                    <ProgressBarLabelBlock value={results[i]}>
                      <span
                        style={{
                          float: 'right',
                          fontFamily: 'lato',
                          color: '#7a8198'
                        }}
                      >
                        {intl.formatMessage(
                          messages.benchmarksAverageLabelWithValue,
                          {
                            value: getResultLabel(avgScores[i], unit)
                          }
                        )}
                      </span>
                    </ProgressBarLabelBlock>
                    <ProgressBar
                      benchmarksWithFrame
                      value={getPercentageOfResult(
                        parseFloat(results[i]),
                        minScores[i],
                        maxScores[i]
                        //   testItem
                      )}
                    />
                    <ProgressBar
                      benchmarksWithFrame
                      range={ProgressBarValueTypes.AVERAGE}
                      value={getPercentageOfResult(
                        avgScores[i],
                        minScores[i],
                        maxScores[i]
                      )}
                    />
                  </>
                ) : (
                  <b>{results[i].label}</b>
                ))}
            </>
          )}
        </CardBody>
      ))}
    </Card>
  );
};

const yScale = (min, max) => {
  return {
    type: 'linear',
    stacked: false,
    min,
    max
  };
};

const axisLeft = {
  orient: 'left',
  tickSize: 0,
  tickPadding: 12,
  tickRotation: 0,
  legend: '',
  legendOffset: -24,
  legendPosition: 'middle'
};
