import { IntlMessageFormat } from 'intl-messageformat';
import messages from 'messages';
import { capitalize } from 'utils/string';

export const getResultLabel = (
  result,
  unit,
  fractionDigits = 2,
  laterality = ''
) => {
  //   if (!fractionDigits) {
  //     // fractionDigits = result - Math.floor(result) !== 0 ? 2 : 0;
  //     fractionDigits = 2;
  //   }

  if (isNaN(parseFloat(result))) return '';

  try {
    const lateralityLabel =
      laterality !== '' && laterality !== 'unilateral'
        ? ` ${capitalize(laterality)} `
        : '';
    const unitFirst = unit && unit === '#' ? unit : '';
    const resultValue = parseFloat(result).toFixed(fractionDigits);
    const unitLast =
      unit && unit.toLowerCase().includes('min')
        ? new IntlMessageFormat(messages.labelUnit.defaultMessage).format({
            count: '',
            unit: 'mins'
          })
        : unit && unit !== '#' && unit.toLowerCase().indexOf('none') === -1
          ? unit
          : '';

    return `${lateralityLabel}${unitFirst} ${resultValue} ${unitLast}`;
  } catch (error) {
    return `${unit && unit === '#' ? unit : ''}${result}${
      unit && unit !== '#' && unit.toLowerCase() !== 'none' ? unit : ''
    }`;
  }
};

//TODO is dat wel nodig? Ja he
export const getPercentageOfResult = (result, min, max) => {
  if (
    parseFloat(result) === parseFloat(min) &&
    parseFloat(min) === parseFloat(max)
  ) {
    return 50;
  } else {
    return ((result - min) / (max - min)) * 100;
  }
};
