import { PanelBody } from 'components/panel';
import GrowthTableWaiting from 'containers/pages/growthtracker/table/GrowthTableWaiting';
import { useGrowthPanelContext } from 'contexts/GrowthPanelContext';
import GrowthTable from 'containers/pages/growthtracker/table/GrowthTable';
import { Button } from 'components/button';
import { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

const GrowthPanel = () => {
  const { loading, cols, columnVisibility } = useGrowthPanelContext();
  const size = useWindowSize();

  const panelRef = useRef();
  const [showButton, setShowButton] = useState(true);
  const [positionCSS, setPositionCSS] = useState({
    top: 'auto',
    bottom: 'auto'
  });

  const checkIfScroll = () => {
    const element = panelRef.current;
    if (!element) return;

    const { scrollLeft, scrollWidth, clientWidth } = element;

    // Check if the user is near the end
    const isAtEnd = scrollLeft + clientWidth >= scrollWidth - 16;
    return isAtEnd;
  };

  const handleScroll = () => {
    setShowButton(!checkIfScroll());
  };

  useEffect(() => {
    const element = panelRef.current;
    if (!element) return;

    console.log('GrowthPanel useEffect triggered');

    setShowButton(!checkIfScroll());

    // Attach scroll event listener
    element.addEventListener('scroll', handleScroll);

    if (!loading) {
      const panelRefClientRect = panelRef?.current.getBoundingClientRect();
      const tableRefClientRect = panelRef?.current
        .querySelector('table')
        .getBoundingClientRect();

      const bottomValue =
        panelRefClientRect.height > tableRefClientRect.height
          ? tableRefClientRect.bottom.toFixed(0)
          : (panelRefClientRect.bottom - 1).toFixed(0);

      const headerPageHeight = document
        .querySelector('.c-page__header')
        .getBoundingClientRect().height;

      setPositionCSS({
        top: `${(tableRefClientRect.top - headerPageHeight).toFixed(0)}px`,
        bottom: size.width > 600 ? `calc(100vh - ${bottomValue}px)` : '0px'
      });
    }

    return () => {
      // Clean up event listener
      element.removeEventListener('scroll', handleScroll);
    };
  }, [cols, size, columnVisibility]);

  const handleClickScrollTo = () => {
    const element = panelRef.current;

    element.scrollBy({
      left: window.innerWidth,
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <PanelBody
        ref={panelRef}
        tabIndex="-1" // De scroller veroorzakt een focusable element 🤔
      >
        {loading ? <GrowthTableWaiting /> : <GrowthTable />}
      </PanelBody>
      {showButton && (
        <Button
          primary
          style={{
            position: 'absolute',
            zIndex: 28,
            right: 0,
            bottom: positionCSS.bottom,
            top: positionCSS.top,
            height: 'auto',
            background:
              'linear-gradient(to right, #0000 10%, #fff6 40%, white 80%)',
            border: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
          onClick={handleClickScrollTo}
        >
          <span
            style={{
              background: 'black',
              padding: '15px 0px 17px 1px',
              borderRadius: 24,
              width: 32,
              height: 32,
              fontSize: 24,
              lineHeight: 1,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            &gt;
          </span>
        </Button>
      )}
    </>
  );
};

export default GrowthPanel;
