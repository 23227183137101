import { TableCell } from 'components/table';
import ProgressBlockValue from 'components/progress-bar/ProgressBlock';
import GrowthPredictionTableWarning from './GrowthPredictionTableWarning';

const GrowthPredictionTableCell = ({ data, inTooltip, onClick }) => {
  return inTooltip ? (
    <div className="c-table__content">
      {data.value && (
        <ProgressBlockValue range={data.range} noClick={data.noClick}>
          {data.value}
          {data.unit}
        </ProgressBlockValue>
      )}
      {data.value && data.alert && <GrowthPredictionTableWarning />}
    </div>
  ) : (
    <TableCell>
      <div className="c-table__content">
        {data.value && (
          <ProgressBlockValue
            range={data.range}
            noClick={data.noClick}
            onClick={onClick}
          >
            {data.value}
            {data.unit}
          </ProgressBlockValue>
        )}
        {data.value && data.alert && <GrowthPredictionTableWarning />}
      </div>
    </TableCell>
  );
};

export default GrowthPredictionTableCell;
