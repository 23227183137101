import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import copy from 'copy-to-clipboard';

import Modal from 'components/modal/Modal';

import messages from 'messages';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import { ButtonsGroup, Button } from 'components/button';
import { CardBody, CardFooter } from 'components/card';
import Textarea from 'components/input/Textarea';
import FieldInput from 'components/input/FieldInput';

const CopyUrlModal = ({ isOpen, content, onClose }) => {
  const intl = useIntl();
  const [value, setValue] = useState('');
  const [isMultiline, setIsMultiline] = useState(false);
  const [lines, setLines] = useState(1);

  useEffect(() => {
    setIsMultiline(content.search('\n') !== -1);
    setValue(content || '');
    setLines(content.split('\n').length);
  }, [content]);

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  const onCopyToClipboard = () => {
    copy(value);
    onCloseHandler();
  };

  return (
    <Modal card autoHeight isOpen={isOpen} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          {intl.formatMessage(
            isMultiline
              ? messages.modalCopyUrlsTitle
              : messages.modalCopyUrlTitle
          )}
        </CardHeaderTitle>
        <CardHeaderSubtitle>
          {intl.formatMessage(
            isMultiline
              ? messages.modalCopyUrlsSubtitle
              : messages.modalCopyUrlSubtitle
          )}
        </CardHeaderSubtitle>
      </CardHeader>

      <CardBody modal>
        {!isMultiline ? (
          <FieldInput url id="sessionurl-id" icon="copy" defaultValue={value}>
            <FormattedMessage {...messages.modalLabelUrl} />
          </FieldInput>
        ) : (
          <Textarea
            id="sessionurl-id"
            icon="copy"
            value={value}
            rows={lines}
            resizeVertical
          >
            <FormattedMessage {...messages.modalLabelUrls} />
          </Textarea>
        )}
      </CardBody>
      <CardFooter modal>
        <ButtonsGroup>
          <Button secondary onClick={onCloseHandler}>
            <FormattedMessage {...messages.buttonCancel} />
          </Button>
          <Button primary onClick={onCopyToClipboard}>
            <FormattedMessage {...messages.modalButtonCopyToClipboard} />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
};

export default CopyUrlModal;
