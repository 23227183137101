import React from 'react';
import './Message.scss';
import classNames from 'classnames';

const MessageText = props => {
  const { children, warning, success, light, extraClassNames, style } = props;

  return (
    <p
      className={classNames('c-message__text', extraClassNames, {
        'c-message__text-warning': warning,
        'c-message__text-success': success,
        'c-message__text-light': light
      })}
      style={style}
    >
      {children}
    </p>
  );
};

export default MessageText;
