import React, { Component } from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const DrawerHeader = props => {
  const { title, small, to, onClick, logo } = props;

  return (
    <Link to={to} className={classNames('c-sidebar__header')} onClick={onClick}>
      <div className={classNames('c-sidebar__logo')}>
        {logo && <img className="c-sidebar__logo-img" src={logo} alt={''} />}
      </div>
      {title && (
        <div
          className={classNames('c-sidebar__user-info', {
            'is-small': small,
            'is-large': props.large
          })}
        >
          {title && title}
        </div>
      )}
    </Link>
  );
};

DrawerHeader.propTypes = {
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string
};

export default DrawerHeader;
