import { Fragment, useContext, useState } from 'react';
import Card from 'components/card/Card';
import CardBody from 'components/card/CardBody';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { Accordion, AccordionContent } from 'components/accordion';
import AccordionButtonHooks from 'components/accordion/AccordionButtonHooks';
import { Table, TableBody, TableCell, TableRow } from 'components/table';
import ExercisesListFiltered from 'containers/partials/lists/ExercisesListFiltered';
import TestItemResultRow from 'containers/partials/cards/ReportCards/TestItemResultRow';
import { checkLateralityIsHidden } from 'utils/tests';
import { StoreContext } from 'index';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

const TrainingAdviceCategoryCard = ({
  entityId,
  category,
  tests,
  options,
  compressedTable,
  onPreview,
  isPrev,
  edit
}) => {
  const {
    uiState: { locale }
  } = useContext(StoreContext);

  const person = useEntityContext(s => s.person);
  const [openList, setOpenList] = useState(null);

  return (
    <Card secondary>
      <CardHeader secondary extraClassNames="u-margin-bottom-small">
        <CardHeaderText>
          <CardHeaderTitle>{category}</CardHeaderTitle>
        </CardHeaderText>
      </CardHeader>
      <CardBody secondary>
        {tests.map((testItem, j) => {
          if (
            testItem?.benchmarked && // TODO als we dit gaan gebruiken voor custom moet de check anders
            Object.keys(testItem.benchmarked).length > 0
          ) {
            let show = false;

            Object.keys(testItem.benchmarked).forEach(side => {
              show =
                show ||
                (testItem.values[side][0] !== null &&
                  testItem.benchmarked[side] !== null);
            });

            const TableWithResults = () => (
              <Table
                key={testItem.id}
                tableReport
                verticalTop
                extraClassNames={
                  'u-margin-bottom-none c-table--results-left-right'
                }
                compressedTable={compressedTable}
              >
                <TableBody>
                  <TableRow
                    extraClassNames={
                      testItem?.benchmarked &&
                      Object.keys(testItem.benchmarked).length > 0
                        ? ''
                        : 'u-1-of-1'
                    }
                  >
                    <TableCell evenHalf colSpan={2}>
                      {testItem.copy?.[locale]?.title ?? testItem.title}
                    </TableCell>
                  </TableRow>
                  <TestItemResultRow
                    testItem={testItem}
                    options={options}
                    hasBenchmarkedValues={true}
                    sidesDisabled={checkLateralityIsHidden(
                      testItem,
                      testItem.sides,
                      person
                    )}
                    isPreventionTest={isPrev}
                    // TODO: @maarten, het verschil tussen deze, en die van
                    // de `PreventionCategoryCard` is dat de andere uit de
                    // `personInjury.sides` komt. Het zou wel handig zijn als
                    // deze view dezelfde results toont dan wat je ziet in
                    // de andere:

                    // relevantSides={testItem.sides}
                    colSpan={Object.keys(testItem.sides)[0] === '2' ? 2 : null}
                  />
                </TableBody>
              </Table>
            );

            if (show) {
              return (
                <Accordion visibleFlow key={testItem.id}>
                  {() => (
                    <Fragment key={`${testItem.id}-${j}`}>
                      {testItem.tags && testItem.tags.length > 0 ? (
                        <AccordionButtonHooks
                          id={`trigger-${testItem.testId}-${testItem.id}-${j}`}
                          name="accordion-story"
                          checkbox
                          checked={
                            openList === `${testItem.testId}-${testItem.id}`
                          }
                          tableTrigger
                          isControlled
                          onChange={checked => {
                            setOpenList(
                              checked
                                ? `${testItem.testId}-${testItem.id}`
                                : false
                            );
                          }}
                        >
                          <TableWithResults />
                        </AccordionButtonHooks>
                      ) : (
                        <TableWithResults />
                      )}

                      {testItem.tags &&
                        testItem.tags.length > 0 &&
                        openList === `${testItem.testId}-${testItem.id}` && (
                          <AccordionContent>
                            <ExercisesListFiltered
                              entityId={entityId}
                              edit={edit}
                              filter={{ tags: testItem.tags }}
                              onPreview={onPreview}
                            />
                          </AccordionContent>
                        )}
                    </Fragment>
                  )}
                </Accordion>
              );
            }
          }
          return null;
        })}
      </CardBody>
    </Card>
  );
};

export default TrainingAdviceCategoryCard;
