import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ROUTE_SIGNUP, ROUTE_TESTS } from './RouteList';
import { StoreContext } from 'index';

const PublicRoute = ({ component, ...rest }) => {
  const {
    authStore: { isLoggedIn, isSigningUp }
  } = useContext(StoreContext);

  if (!isLoggedIn || rest.path === ROUTE_TESTS || isSigningUp) {
    return <Route {...rest}>{component}</Route>;
  }

  return <Redirect to="/" />;
};

export default PublicRoute;
