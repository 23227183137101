import awsconfig from 'services/aws/config/aws-exports';
import * as Sentry from '@sentry/react';

import { Amplify } from 'aws-amplify';
import {
  signIn,
  signOut,
  confirmSignIn,
  setUpTOTP,
  verifyTOTPSetup,
  updateMFAPreference,
  fetchAuthSession,
  getCurrentUser,
  fetchUserAttributes,
  fetchMFAPreference
} from 'aws-amplify/auth';

export const connect = async () => {
  Amplify.configure(awsconfig);
};

export const login = async credentials => {
  if (!credentials?.password) {
    credentials.options = {
      authFlowType: 'CUSTOM_WITHOUT_SRP'
    };
  }
  return await signIn(credentials);
};

export const loginWithEmail = async username => {
  return await signIn({ username });
};

export const customChallengeAnswer = async token => {
  return await confirmSignIn(token);
};

export const loginWithTotpCode = async code => {
  return await confirmSignIn(
    { challengeResponse: code } // Confirmation code
  );
};

export const getCurrentSession = async () => {
  try {
    const currentSession = await fetchAuthSession();
    const currentAccessToken = await currentSession.accessToken;
    const currentJwtToken = await currentAccessToken.jwtToken;
    return {
      authenticated: true,
      jwtToken: currentJwtToken
    };
  } catch (error) {
    Sentry.captureException(error);
    return { authenticated: false };
  }
};

export const getUserAttributes = async () => {
  try {
    return await fetchUserAttributes();
  } catch (error) {
    Sentry.captureException(error);
    return { error };
  }
};

export const completeNewPassword = async password => {
  return await confirmSignIn({ challengeResponse: password });
};

export const logOut = async () => {
  await signOut();
};

export const getCurrentAuthenticatedUser = async () => {
  try {
    return await getCurrentUser();
  } catch (error) {
    throw error;
  }
};

export const getPreferredMFA = async () => {
  return await fetchMFAPreference();
};

export const getQRCode = async () => {
  try {
    return await setUpTOTP();
  } catch (error) {
    throw error;
  }
};

export const verifyTotpToken = async code => {
  try {
    await verifyTOTPSetup({ code });
    await updateMFAPreference({ totp: 'ENABLED' });
    return true;
  } catch (error) {
    return false;
  }
};

export const removeMFA = async () => {
  try {
    return await updateMFAPreference({ totp: 'DISABLED' });
  } catch (error) {
    throw error;
  }
};
