import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import { QRCodeSVG } from 'qrcode.react';
import { useRef } from 'react';
import { Button, ButtonsGroup } from 'components/button';
import messages from 'messages';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';

const GenerateQRCodeModal = ({
  entityId,
  persons,
  session,
  type,
  onClose,
  group = null
}) => {
  const printArea = useRef(null);
  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  const getQRObject = (type, sporter) => {
    if (type === 'scanner') {
      const code = `P${sporter.firstname} ${sporter.lastname} ${format(sporter.birthdate, 'ddLLyyyy')}`;
      // append 1 chars until string is 43 bytes long and append a new line
      const codeWithPadding = `${code} ${'1'.repeat(43 - code.length)}\n`;
      return codeWithPadding;
      // return `P${sporter.firstname} ${sporter.lastname} ${format(sporter.birthdate, 'ddLLyyyy')}`;
    } else {
      return JSON.stringify({
        e: entityId,
        p: sporter.id,
        s: session?.id || ''
      });
    }
  };

  const printPageTitle = group
    ? `Print QR Codes for ${group.name}`
    : 'Print QR Codes';

  const printQRs = () => {
    const printElement = printArea.current.innerHTML;
    const printWindow = window.open('', '_blank', 'width=600,height=600');
    printWindow.document.write(`
          <html>
            <head>
              <title>${printPageTitle}</title>
                <style>
                    body {
                        margin: 0;
                        padding: 0;
                        font-family: Arial, sans-serif;
                    }
                    .qr-container {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                    }
                    .qr-code {
                        width: 30%;
                        margin-bottom: 20px;
                    }
                    @media print {
                        .qr-container {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            justify-content: space-between;
                        }
                        .qr-code {
                            width: 30%;
                            margin: 0;
                            page-break-inside: avoid; /* Prevent page breaks inside QR codes */
                        }
                        .no-print {
                            display: none; /* Hide non-printable content */
                        }
                    }
                </style>
              </head>
            <body>
              <h1>${printPageTitle}</h1>
              <div class="qr-container">
                ${printElement}
              </div>
            </body>
          </html>
            `);

    // Close the document to finish loading
    printWindow.document.close();

    // Wait for the content to be rendered before printing
    printWindow.onload = () => {
      printWindow.print();
      printWindow.close();
    };
  };

  return (
    <Modal card largeBody isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.modalQRCodesTitle} />
        </CardHeaderTitle>
      </CardHeader>

      <CardBody modal>
        <div className="" ref={printArea}>
          <div className="o-layout o-layout--tiny qr-container">
            {persons.map(sporter => {
              return (
                <div
                  className="o-layout__item u-1-of-3-at-small qr-code"
                  key={sporter.id}
                  style={{ marginBottom: '80px' }}
                >
                  <div>
                    <h3>
                      {sporter.firstname} {sporter.lastname}
                    </h3>
                    <QRCodeSVG value={getQRObject(type, sporter)} size={180} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </CardBody>
      <CardFooter modal extraClassNames="c-card__footer--modal-larger">
        <ButtonsGroup>
          <Button type="button" primary onClick={printQRs}>
            <FormattedMessage {...messages.buttonPrint} />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
};

export default GenerateQRCodeModal;
