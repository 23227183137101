import React, { useContext, useRef, useState } from 'react';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import {
  ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION,
  ROUTE_SESSIONS_ENTITY,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS
} from 'routes/RouteList';
import { createIntl, FormattedMessage } from 'react-intl';
import messages from 'messages';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import MenuWrapper from 'components/menu/MenuWrapper';
import PanelTitleButton from 'components/panel/panel-title/PanelTitleButton';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import DownloadEmptyFormButton from 'containers/partials/buttons/DownloadEmptyFormButton';
import DownloadReportOptionsModal from 'containers/partials/modals/DownloadReportOptionsModal';
import CopySessionButton from 'containers/partials/buttons/CopySessionButton';
import RemoveSessionButton from 'containers/partials/buttons/RemoveSessionButton';
import RemoveSporterFromSessionButton from 'containers/partials/buttons/RemoveSporterFromSessionButton';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import PanelHeader from 'components/panel/PanelHeader';
import { StoreContext } from 'index';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import MailReportOptionsModal from 'containers/partials/modals/MailReportOptionsModal';
import {
  SessionContextProvider,
  useSessionContext
} from 'contexts/SessionContext';
import Badge from 'components/badge/Badge';
import GenerateQRCodeModal from 'containers/partials/modals/GenerateQRCodeModal';
import AssignPersonsToSessionModal from 'containers/pages/sessions/AssignPersonsToSessionModal';
import { MenuContextProvider } from 'contexts/MenuContext';
import {
  createEntityStore,
  EntityStoreProvider
} from 'containers/pages/persons/store/EntityStoreContext';
import { sortSportersOnFinishedAndDate } from 'utils/sort';
import DownloadReportsButton from 'containers/partials/session-report-buttons/DownloadReportsButton';
import slugify from 'slugify';
import ExportReportButton from 'containers/partials/session-report-buttons/ExportReportButton';

const SessionHeader = ({
  tabIndex,
  selectedSporters,
  title,
  subtitle,
  isExpired,
  onSessionCopy,
  onClearSelected
}) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);

  const { push } = useHistory();
  const enIntl = createIntl({
    locale: 'en'
  });

  const { entityId } = useParams();
  const ability = useAbility(AbilityContext);
  const { session } = useSessionContext();

  const entityStore = useRef(createEntityStore()).current;

  const [showReportOptionsModal, setShowReportOptionsModal] = useState(false);
  const [showMailReportOptionsModal, setShowMailReportOptionsModal] =
    useState(false);
  const [showQrModal, setShowQrModal] = useState(false);
  const [showAddPersonsModal, setShowAddPersonsModal] = useState(null);

  const onEditPreventionHandler = () => {
    push(
      generatePath(ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION, {
        entityId,
        type: session.preventionType,
        sessionId: session.id
      })
    );
  };

  return (
    <>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            onClick={() =>
              push(generatePath(ROUTE_SESSIONS_ENTITY, { entityId }))
            }
          >
            <FormattedMessage {...messages.breadcrumbSessions} />
          </Breadcrumb>
          <Breadcrumb>
            <FormattedMessage
              {...messages.breadcrumbSession}
              values={{ session: session.name }}
            />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <div>
              {isExpired && session.recurring && (
                <Badge
                  variant="badge-neutral"
                  extraClassNames="u-margin-right-small"
                >
                  <FormattedMessage {...messages.badgeSessionEnded} />
                </Badge>
              )}
              {title}
            </div>
            {!user.isAthlete() && (
              <MenuWrapper
                trigger={<PanelTitleButton dataQa={slugify(title)} />}
              >
                {session.isPrevention && (
                  <Button
                    menu
                    onClick={onEditPreventionHandler}
                    dataQa={`${slugify(
                      enIntl
                        .formatMessage(messages.preventionAddExtraTestsButton)
                        .toLowerCase()
                    )}-menu`}
                  >
                    <Icon id="add" />
                    <FormattedMessage
                      {...messages.preventionAddExtraTestsButton}
                    />
                  </Button>
                )}

                {/* Add Athlete: only visible on Athlete tab */}
                {tabIndex === 1 && (
                  <Button
                    menu
                    onClick={() => setShowAddPersonsModal(true)}
                    dataQa={`${slugify(
                      enIntl
                        .formatMessage(messages.buttonSessionAddSporters)
                        .toLowerCase()
                    )}-menu`}
                  >
                    <Icon id="add" />
                    <FormattedMessage {...messages.buttonSessionAddSporters} />
                  </Button>
                )}

                <DownloadEmptyFormButton session={session} />

                {session.finishedPercentage !== 0 && !session.isRecurring && (
                  <>
                    <ExportReportButton session={session} />
                    <DownloadReportsButton
                      type={'group'}
                      modalCallback={callBack =>
                        setShowReportOptionsModal(callBack)
                      }
                    />
                  </>
                )}

                <CopySessionButton
                  menu
                  entityId={entityId}
                  session={session}
                  onCopySession={session => {
                    onSessionCopy(session);
                  }}
                />

                {ability.can('delete', 'Sessions') && (
                  <RemoveSessionButton
                    menu
                    entityId={entityId}
                    sessionId={session.id}
                    onComplete={() =>
                      push(ROUTE_SESSIONS_ENTITY.replace(':entityId', entityId))
                    }
                    dataQa={`${slugify(
                      enIntl
                        .formatMessage(messages.menuItemSessionRemoveSession)
                        .toLowerCase()
                    )}-menu`}
                  >
                    <FormattedMessage
                      {...messages.menuItemSessionRemoveSession}
                    />
                  </RemoveSessionButton>
                )}

                {/* Remove Athlete: only visible on Athlete tab */}
                {tabIndex === 1 && !user.isAthlete() && (
                  <RemoveSporterFromSessionButton
                    menu
                    ids={selectedSporters.map(sporter => sporter.id)}
                    sessionId={session.id}
                    rootEntityId={entityId}
                    onComplete={() => {
                      onClearSelected && onClearSelected();
                      push(
                        generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS, {
                          entityId,
                          sessionsTab: 'sessions',
                          sessionId: session.id
                        })
                      );
                    }}
                    disabled={selectedSporters.length === 0}
                    dataQa={`${slugify(
                      enIntl
                        .formatMessage(messages.menuItemSessionRemoveSporters)
                        .toLowerCase()
                    )}-menu`}
                  >
                    <FormattedMessage
                      {...messages.menuItemSessionRemoveSporters}
                    />
                  </RemoveSporterFromSessionButton>
                )}
                {user.superadmin && (
                  <Button
                    onClick={() => setShowQrModal(true)}
                    menu
                    disabled={selectedSporters.length === 0}
                    dataQa={`${slugify(
                      enIntl
                        .formatMessage(messages.menuItemSessionGenerateQRCodes)
                        .toLowerCase()
                    )}-menu`}
                  >
                    <FormattedMessage
                      {...messages.menuItemSessionGenerateQRCodes}
                    />
                  </Button>
                )}
              </MenuWrapper>
            )}
          </PanelTitle>
          <PanelSubtitle
            extraClassNames={
              session.isRecurring ? '.c-panel__subtitle-capitalized' : ''
            }
          >
            {subtitle}
          </PanelSubtitle>
        </PanelTitleWrapper>
        {/*
              Eventually we could add a button to add athletes,
              and a search field for athletes
               */}
        {/*<PanelTools>
                <FieldInput
                  rounded
                  type="text"
                  icon="search-grey"
                  iconLeft
                  //   onChange={onSearch}
                />
               {!user.isAthlete() && tabIndex === 1 && (
                  <Button
                    rounded
                    primary
                    onClick={() =>
                      push(
                        generatePath(
                          ROUTE_SESSIONS_ENTITY_SESSION_ADD_SPORTER,
                          { entityId, sessionId }
                        )
                      )
                    }
                  >
                    <Icon id="add-white" />
                    <FormattedMessage {...messages.buttonSessionAddSporters} />
                  </Button>
                )}
                </PanelTools> */}
      </PanelHeader>

      {showMailReportOptionsModal && (
        <MailReportOptionsModal
          onClose={() => setShowMailReportOptionsModal(false)}
        />
      )}

      {showReportOptionsModal && (
        <DownloadReportOptionsModal
          callback={showReportOptionsModal}
          onClose={() => setShowReportOptionsModal(false)}
        />
      )}
      {showQrModal && (
        <GenerateQRCodeModal
          onClose={() => setShowQrModal(false)}
          persons={sortSportersOnFinishedAndDate(session.sporters)}
          session={session}
          type="session"
          entityId={entityId}
        />
      )}
      {showAddPersonsModal && (
        <MenuContextProvider>
          <SessionContextProvider>
            <EntityStoreProvider value={entityStore}>
              <AssignPersonsToSessionModal
                entityId={entityId}
                onClose={() => {
                  setShowAddPersonsModal(null);
                }}
                onDone={() => {
                  setShowAddPersonsModal(null);
                }}
              />
            </EntityStoreProvider>
          </SessionContextProvider>
        </MenuContextProvider>
      )}
    </>
  );
};

export default SessionHeader;
