import { getResultLabel } from './benchmark';
import {
  ColorNamesForGrowthValues,
  ProgressBarRange,
  ProgressBarValueTypes
} from 'constants.js';

export const getTotalBestScoreString = item => {
  let score = item.calculation
    ? getTotalBestScore(
        item.calculation.type,
        item.calculation.best_value,
        item.values
      )
    : null;
  if (score !== null) {
    return getResultLabel(score, item.unit);
  }
  return '';
};

export const getTotalBestScore = (type, best_value, values) => {
  let score;
  if (
    values &&
    values.length &&
    !values.some(v => v === null || v === undefined)
  ) {
    switch (type) {
      case 'best_performance':
      case 'Best Performance':
        score = best_value === 1 ? Math.max(...values) : Math.min(...values);
        break;
      case 'sum':
      case 'Sum':
        score = values.reduce((partial_sum, a) => partial_sum + a, 0);
        break;
      default:
        score = values[0];
    }
    return score;
  }
  return null;
};

export const getProgressBarColorByPercentile = percent => {
  const rangeIndex = ProgressBarRange.findIndex(
    (range, index) =>
      percent >= range &&
      (ProgressBarRange[index + 1]
        ? percent < ProgressBarRange[index + 1]
        : 100)
  );
  return Object.values(ProgressBarValueTypes)[rangeIndex];
};

export const getGrowthColor = value => {
  return Object.values(ColorNamesForGrowthValues)[value];
};
