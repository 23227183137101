import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonsGroup } from 'components/button';
import { useIntl } from 'react-intl';
import messages from 'messages';
import { Card, CardBody, CardFooter } from 'components/card';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import FieldInput from 'components/input/FieldInput';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { StoreContext } from 'index';

const Signup = () => {
  const intl = useIntl();
  const { authStore } = useContext(StoreContext);
  const [errorMessage, setErrorMessage] = useState('');
  const name = '';

  const handleSubmit = async ({ password1 }) => {
    const user = await authStore.doSignup(password1);
    if (!user || user.error) {
      setErrorMessage(messages.errorSomethingWentWrong);
    }
  };

  const validationSchema = Yup.object().shape({
    password1: Yup.string()
      .required(intl.formatMessage(messages.errorPasswordEmpty))
      .min(8, intl.formatMessage(messages.errorPasswordTooShort))
      .matches(
        /[a-z]/i,
        intl.formatMessage(messages.errorPasswordNoAlphabeticalChar)
      )
      .matches(
        /[0-9]/i,
        intl.formatMessage(messages.errorPasswordNoNumericalChar)
      ),
    password2: Yup.string().oneOf(
      [Yup.ref('password1'), null],
      intl.formatMessage(messages.errorPasswordNotEqual)
    )
  });

  return (
    <Card>
      <Formik
        initialValues={{ password1: '', password2: '' }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, isValid, dirty, handleBlur }) => {
          return (
            <Form noValidate>
              <CardHeader primary>
                <CardHeaderTitle>
                  <FormattedMessage
                    {...messages.signupTitle}
                    values={{ name }}
                  />
                </CardHeaderTitle>
                <CardHeaderSubtitle extraClassNames="u-margin-horizontal-auto">
                  <FormattedMessage {...messages.signupMessage} />
                </CardHeaderSubtitle>
              </CardHeader>
              <CardBody primary>
                <FieldInput
                  id="password1"
                  name="password1"
                  type="password"
                  errors={errors}
                  touched={touched}
                  onBlur={handleBlur}
                >
                  <FormattedMessage {...messages.signupPasswordLabel} />
                </FieldInput>
                <FieldInput
                  id="password2"
                  name="password2"
                  type="password"
                  errors={errors}
                  touched={touched}
                  onBlur={handleBlur}
                >
                  <FormattedMessage {...messages.signupPasswordRepeatLabel} />
                </FieldInput>
                {errorMessage && <FormattedMessage {...errorMessage} />}
              </CardBody>
              <CardFooter primary>
                <ButtonsGroup>
                  <Button primary type="submit" disabled={!isValid}>
                    <FormattedMessage {...messages.signupSubmitButton} />
                  </Button>
                </ButtonsGroup>
              </CardFooter>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default Signup;
