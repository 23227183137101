import { useContext, useEffect, useState } from 'react';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import { removePersonFromOrganisation } from 'services/aws/client-query';
import { StoreContext } from 'index';
import ConfirmButton from 'containers/partials/buttons/ConfirmButton';
import useFetchAndFilterPersonsPool from 'hooks/queries/useFetchAndFilterPersonsPool';
import useFetchAndFilterEntities from 'hooks/queries/useFetchAndFilterEntities';

const RemovePersonsFromOrgButton = ({
  entityId,
  groupId,
  persons,
  children,
  onComplete,
  isPoolPage,
  ...props
}) => {
  const { uiState } = useContext(StoreContext);
  const [personNames, setPersonNames] = useState('');
  const { fetchPool } = useFetchAndFilterPersonsPool({ entityId });
  const { fetchEntities } = useFetchAndFilterEntities({ entityId });

  useEffect(() => {
    if (Array.isArray(persons) && persons.length > 0) {
      const names = persons.map(person => person.fullName).join(', ');
      setPersonNames(names);
    }
  }, [persons]);

  const onConfirmRemovePerson = async persons => {
    if (Array.isArray(persons) && persons.length > 0) {
      uiState.increasePendingRequest();
      for (const person of persons) {
        await removePersonFromOrganisation({ personId: person.id, entityId });
      }
      await fetchPool();
      if (!isPoolPage) {
        await fetchEntities({ entityId: groupId });
      }
      uiState.decreasePendingRequest();

      onComplete && onComplete();
    }
  };

  return (
    <ConfirmButton
      {...props}
      menu
      confirmTitle={messages.modalConfirmRemovePersonFromOrgTitle}
      confirmMessage={
        messages.modalConfirmRemovePersonFromOrgMessageWithListPersons
      }
      messageValues={{
        count: persons.length,
        listPersons: personNames,
        b: chunks => <b>{chunks}</b>,
        br: () => <br />
      }}
      onOk={() => onConfirmRemovePerson(persons)}
    >
      <Icon id="delete" />
      {children}
    </ConfirmButton>
  );
};

export default RemovePersonsFromOrgButton;
