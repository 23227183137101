import React, { Fragment, useContext, useRef } from 'react';
import { observer } from 'mobx-react';
import { generatePath, Redirect, withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

import Overview from './Overview';

import Users from './users/Users';
import User from './users/User';

import {
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_SUB,
  ROUTE_SETTINGS_USERS,
  ROUTE_SETTINGS_USERS_ADD,
  ROUTE_SETTINGS_USERS_USER,
  ROUTE_SETTINGS_USERS_USER_EDIT,
  ROUTE_SETTINGS_USERS_USER_ROLES,
  ROUTE_SETTINGS_ORGANISATIONS,
  ROUTE_SETTINGS_ORGANISATIONS_ORG,
  ROUTE_SETTINGS_ORGANISATIONS_ADD,
  ROUTE_SETTINGS_ORGANISATIONS_ORG_EDIT,
  ROUTE_SETTINGS_GLOBAL,
  ROUTE_SETTINGS_GLOBAL_EDIT,
  ROUTE_SETTINGS_BENCHMARKS,
  ROUTE_SETTINGS_BENCHMARKS_BENCHMARK,
  ROUTE_SETTINGS_BENCHMARKS_BENCHMARK_TEST,
  ROUTE_SETTINGS_TESTSETS_TESTSET,
  ROUTE_SETTINGS_TESTSETS,
  ROUTE_SETTINGS_BENCHMARKS_BENCHMARK_TEST_EDIT,
  ROUTE_SETTINGS_USAGE,
  ROUTE_SETTINGS_DEMODATA,
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SETTINGS_PROFILE,
  ROUTE_SETTINGS_PROFILE_MFA,
  ROUTE_SETTINGS_PROFILE_EDIT,
  ROUTE_SETTINGS_EXPORT_DATA,
  ROUTE_SETTINGS_SEARCH_USERS
} from 'routes/RouteList';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import Organisations from './organisations/Organisations';
import Organisation from './organisations/Organisation';
import GlobalSettings from './global/GlobalSettings';
import Benchmarks from './benchmarks/Benchmarks';
import Benchmark from './benchmarks/Benchmark';
import BenchmarkTest from './benchmarks/BenchmarkTest';
import TestSets from './testsets/TestSets';
import TestSet from './testsets/TestSet';
import { PreviewModalProvider } from 'contexts/PreviewModalContext';
import { TestSetContextProvider } from 'contexts/TestSetContext';
import { TestsProvider } from 'contexts/TestsContext';
import { StoreContext } from 'index';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import Usage from 'containers/pages/settings/usage/Usage';
import DemoData from 'containers/pages/settings/demodata/DemoData';
import NoAccessPanel from 'containers/pages/auth/NoAccessPanel';
import MfaSetup from 'containers/pages/settings/profile/MfaSetup';
import Profile from 'containers/pages/settings/profile';
import ExportData from 'containers/pages/settings/export/ExportData';
import {
  createEntityStore,
  EntityStoreProvider
} from 'containers/pages/persons/store/EntityStoreContext';
import SearchUsers from 'containers/pages/settings/search-users/SearchUsers';

const Settings = () => {
  const {
    authStore: {
      user,
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const intl = useIntl();
  const ability = useAbility(AbilityContext);
  const entityStore = useRef(createEntityStore()).current;

  if (ability.cannot('manage', 'Settings')) {
    return (
      <NoAccessPanel
        entityId={user.rootEntityId}
        emptyLabel={intl.formatMessage(messages.noAccessEmptyPanel)}
        redirectRoute={generatePath(ROUTE_SPORTERS_ENTITY, {
          entityId: user.rootEntityId
        })}
      />
    );
  }

  return (
    <Fragment>
      <EntityStoreProvider value={entityStore}>
        <PreviewModalProvider entityId={rootEntityId}>
          <TestsProvider entityId={rootEntityId}>
            <TestSetContextProvider entityId={rootEntityId}>
              <Switch>
                <Route exact path={ROUTE_SETTINGS} render={Overview} />
                <Route
                  path={ROUTE_SETTINGS_SUB}
                  render={props => (
                    <Overview
                      setting={props.match.params.setting}
                      match={props.match}
                    />
                  )}
                />
              </Switch>
              <Route
                exact
                path={ROUTE_SETTINGS}
                render={() => (
                  <Panel
                    emptyState
                    emptyLabel={intl.formatMessage(messages.usersEmptyPanel)}
                  />
                )}
              />
              <Switch>
                <Route
                  exact
                  path={ROUTE_SETTINGS_USERS}
                  render={() => <Users />}
                />
                {ability.can('read', 'Organisations') && (
                  <Route
                    exact
                    path={ROUTE_SETTINGS_ORGANISATIONS}
                    render={() => <Organisations />}
                  />
                )}
                {ability.can('read', 'Benchmarks') && (
                  <Route
                    exact
                    path={ROUTE_SETTINGS_BENCHMARKS}
                    render={() => <Benchmarks />}
                  />
                )}
                {ability.can('read', 'Testsets') && (
                  <Route
                    exact
                    path={ROUTE_SETTINGS_TESTSETS}
                    render={() => <TestSets />}
                  />
                )}
                {ability.can('read', 'Organisation') && (
                  <Route
                    path={ROUTE_SETTINGS_GLOBAL_EDIT}
                    render={() => <GlobalSettings edit />}
                  />
                )}
                {ability.can('read', 'Organisation') && (
                  <Route
                    exact
                    path={ROUTE_SETTINGS_GLOBAL}
                    render={() => <GlobalSettings />}
                  />
                )}
                <Route
                  path={ROUTE_SETTINGS_USERS_USER}
                  render={props => (
                    <Users
                      entityId={rootEntityId}
                      userId={props.match.params.userId}
                    />
                  )}
                />
                {ability.can('read', 'Organisations') && (
                  <Route
                    path={ROUTE_SETTINGS_ORGANISATIONS_ORG}
                    render={props => (
                      <Organisations
                        organisationId={props.match.params.organisationId}
                      />
                    )}
                  />
                )}
                {ability.can('manage', 'Benchmarks') && (
                  <Route
                    path={ROUTE_SETTINGS_BENCHMARKS_BENCHMARK}
                    render={props => (
                      <Benchmarks
                        benchmarkId={props.match.params.benchmarkId}
                      />
                    )}
                  />
                )}
                {ability.can('manage', 'Testsets') && (
                  <Route
                    path={ROUTE_SETTINGS_TESTSETS_TESTSET}
                    render={props => (
                      <TestSets
                        entityId={props.match.params.entityId}
                        testSetId={props.match.params.testSetId}
                        type={props.match.params.type}
                      />
                    )}
                  />
                )}
                {ability.can('manage', 'Organisation') && (
                  <Route path={ROUTE_SETTINGS_USAGE} render={() => <Usage />} />
                )}
                {ability.can('manage', 'Organisation') && (
                  <Route
                    path={ROUTE_SETTINGS_EXPORT_DATA}
                    render={() => <ExportData />}
                  />
                )}
                {ability.can('manage', 'Organisation') && (
                  <Route
                    path={ROUTE_SETTINGS_DEMODATA}
                    render={() => <DemoData />}
                  />
                )}
                <Route
                  path={ROUTE_SETTINGS_PROFILE_MFA}
                  render={() => <MfaSetup />}
                />
                <Route
                  path={[ROUTE_SETTINGS_PROFILE_EDIT, ROUTE_SETTINGS_PROFILE]}
                  render={props => (
                    <Profile
                      entityId={rootEntityId}
                      edit={props.match.path.endsWith('edit')}
                    />
                  )}
                />
                <Route
                  path={ROUTE_SETTINGS_SEARCH_USERS}
                  render={() => <SearchUsers />}
                />
                <Redirect to={ROUTE_SETTINGS} />
              </Switch>
              <Switch>
                {ability.can('create', 'Users') && (
                  <Route
                    path={ROUTE_SETTINGS_USERS_ADD}
                    render={() => (
                      <User entityId={rootEntityId} tabIndex={0} edit />
                    )}
                  />
                )}
                {ability.can('create', 'Organisations') && (
                  <Route
                    path={ROUTE_SETTINGS_ORGANISATIONS_ADD}
                    render={() => (
                      <Organisation organisationId={null} edit={true} />
                    )}
                  />
                )}
                <Switch>
                  {ability.can('manage', 'Users') && (
                    <Route
                      path={ROUTE_SETTINGS_USERS_USER_EDIT}
                      render={props => (
                        <User
                          userId={props.match.params.userId}
                          entityId={rootEntityId}
                          tabIndex={0}
                          edit
                        />
                      )}
                    />
                  )}
                  {ability.can('manage', 'Organisations') && (
                    <Route
                      path={ROUTE_SETTINGS_ORGANISATIONS_ORG_EDIT}
                      render={props => (
                        <Organisation
                          organisationId={props.match.params.organisationId}
                          edit
                        />
                      )}
                    />
                  )}
                  <Route
                    path={ROUTE_SETTINGS_USERS_USER_ROLES}
                    render={props => (
                      <User
                        userId={props.match.params.userId}
                        entityId={rootEntityId}
                        tabIndex={1}
                      />
                    )}
                  />
                  <Route
                    path={ROUTE_SETTINGS_USERS_USER}
                    render={props => (
                      <User
                        entityId={rootEntityId}
                        userId={props.match.params.userId}
                        tabIndex={0}
                      />
                    )}
                  />
                  {ability.can('read', 'Organisations') && (
                    <Route
                      path={ROUTE_SETTINGS_ORGANISATIONS_ORG}
                      render={props => (
                        <Organisation
                          organisationId={props.match.params.organisationId}
                        />
                      )}
                    />
                  )}
                  {ability.can('read', 'Benchmarks') && (
                    <Route
                      exact
                      path={ROUTE_SETTINGS_BENCHMARKS_BENCHMARK}
                      render={props => (
                        <Benchmark
                          benchmarkId={props.match.params.benchmarkId}
                        />
                      )}
                    />
                  )}
                  {ability.can('read', 'Testsets') && (
                    <Route
                      exact
                      path={ROUTE_SETTINGS_TESTSETS_TESTSET}
                      render={props => (
                        <TestSet
                          entityId={props.match.params.entityId}
                          testSetId={props.match.params.testSetId}
                          type={props.match.params.type}
                        />
                      )}
                    />
                  )}
                  {ability.can('read', 'Benchmarks') && (
                    <Route
                      path={ROUTE_SETTINGS_BENCHMARKS_BENCHMARK_TEST}
                      render={props => (
                        <Benchmark
                          benchmarkId={props.match.params.benchmarkId}
                          benchmarkTestId={props.match.params.benchmarkTestId}
                        />
                      )}
                    />
                  )}
                </Switch>
              </Switch>
              <Switch>
                {ability.can('update', 'Benchmarks') && (
                  <Route
                    path={ROUTE_SETTINGS_BENCHMARKS_BENCHMARK_TEST_EDIT}
                    render={props => (
                      <BenchmarkTest
                        edit
                        benchmarkId={props.match.params.benchmarkId}
                        benchmarkTestId={props.match.params.benchmarkTestId}
                      />
                    )}
                  />
                )}
                {ability.can('read', 'Benchmarks') && (
                  <Route
                    path={ROUTE_SETTINGS_BENCHMARKS_BENCHMARK_TEST}
                    render={props => (
                      <BenchmarkTest
                        benchmarkId={props.match.params.benchmarkId}
                        benchmarkTestId={props.match.params.benchmarkTestId}
                      />
                    )}
                  />
                )}
              </Switch>
            </TestSetContextProvider>
          </TestsProvider>
        </PreviewModalProvider>
      </EntityStoreProvider>
    </Fragment>
  );
};

export default withRouter(observer(Settings));
