import React, { forwardRef } from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';

import Icon from '../icon/Icon';
import { Link } from 'react-router-dom';
import Badge from 'components/badge/Badge';

const NavigationItem = forwardRef((props, ref) => {
  const {
    id,
    icon,
    iconSize,
    active,
    secondary,
    signout,
    bottom,
    to,
    onClick,
    style,
    label,
    isNew
  } = props;

  return (
    <Link
      ref={ref}
      id={id}
      className={classNames('c-nav__item', {
        'c-nav__item--secondary': secondary,
        'c-nav__item--bottom': bottom,
        'is-active': active,
        'c-nav__item--signout': signout
      })}
      onClick={onClick ? onClick : null}
      to={to}
      style={style}
      title={label}
      data-qa={`nav-${id}`}
    >
      {icon && (
        <div className="c-nav__icon">
          <Icon
            id={icon}
            fillColor={
              active || isNew ? 'color-neutral-x-light' : 'color-neutral-dark'
            }
            mediumIcon={!iconSize}
            hugeIcon={iconSize === 'huge'}
          />
        </div>
      )}
      <span
        className={classNames('c-nav__item-description', {
          'o-flex o-flex--justify-space-between o-flex--align-center': isNew
        })}
      >
        {label}
        {isNew && (
          <Badge variant="badge-neutral" lighterFont>
            new
          </Badge>
        )}
      </span>
    </Link>
  );
});

NavigationItem.propTypes = {
  icon: PropTypes.string,
  iconColor: PropTypes.string
};

export default NavigationItem;
