import { Card, CardBody } from 'components/card';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { Table, TableBody, TableRow } from 'components/table';
import { uid } from 'react-uid';
import TableCell from 'components/table/TableCell';
import Graphic from 'components/graphics/Graphic';
import ProgressBarGroup from 'components/progress-bar/ProgressBarGroup';
import ProgressBarLabelBlock from 'components/progress-bar/ProgressBarLabelBlock';
import { Test } from 'constants.js';

const GrowthPredictionCard = ({ results }) => {
  const intl = useIntl();
  const maturityEst = [
    {
      name: '(K-R)',
      testItems: []
    },
    {
      name: '(M)',
      testItems: []
    },
    {
      name: '(SB)',
      testItems: []
    }
  ];

  results.map((result, i) => {
    // Maturity Estimations (K-R)
    // Bio Age (K-R)
    if (result.id === Test.BIO_AGE) maturityEst[0].testItems.push(result);

    // Chrono vs Bio age
    if (result.id === Test.CHRONO_VS_BIO_AGE)
      maturityEst[0].testItems.push(result);

    // Adult Height Prediction (K-R)
    if (result.id === Test.ADULT_HEIGHT_PREDICTION_K_R)
      maturityEst[0].testItems.push(result);

    // Adult Height Prediction 90% CI
    if (result.id === Test.ADULT_HEIGHT_PREDICTION_CI)
      maturityEst[0].testItems.push(result);

    // % Adult Height Prediction
    if (result.id === Test.PERCENT_ADULT_HEIGHT_PREDICTION)
      maturityEst[0].testItems.push(result);

    // Growth Phase
    if (result.id === Test.GROWTH_PHASE) maturityEst[0].testItems.push(result);

    // Development (K-R)
    if (result.id === Test.MATURATION_TIMING)
      maturityEst[0].testItems.push(result);

    // Development (K-R) Z-score
    if (result.id === Test.DEVELOPMENT_Z_SCORE)
      maturityEst[0].testItems.push(result);

    // Maturity Estimations (M)
    // Biological Age (M)
    if (result.id === Test.BIOLOGICAL_AGE_M)
      maturityEst[1].testItems.push(result);

    // APHV
    if (result.id === Test.APHV) maturityEst[1].testItems.push(result);

    // Years from APHV (Maturity Offset)
    if (result.id === Test.YEARS_FROM_APHV)
      maturityEst[1].testItems.push(result);

    // Adult Height Prediction (M) v2
    if (result.id === Test.ADULT_HEIGHT_PREDICTION_M_V2)
      maturityEst[1].testItems.push(result);

    // Development (M)
    if (result.id === Test.DEVELOPMENT_M) maturityEst[1].testItems.push(result);

    // Maturity Estimations (SB)
    // Sonic Bone (SB)
    if (result.id === Test.SONIC_BONE_SB) maturityEst[2].testItems.push(result);
    // Chrono vs Bio age
    if (result.id === Test.SKELETAL_AGE_SB)
      maturityEst[2].testItems.push(result);
    // Age at test date
    if (result.id === Test.AGE_AT_TEST_DATE)
      maturityEst[2].testItems.push(result);
    // % Adult Height Prediction (SB)
    if (result.id === Test.PERCENT_ADULT_HEIGHT_PREDICTION_SB)
      maturityEst[2].testItems.push(result);
    // Difference age at test date and skeletal age
    if (result.id === Test.AGE_AT_TEST_DATE_VS_SKELETAL_AGE)
      maturityEst[2].testItems.push(result);
  });

  return maturityEst.map((group, index) => {
    if (group.testItems.length === 0) return null;
    return (
      <Card secondary key={index}>
        <CardHeader secondary extraClassNames="u-margin-bottom-small">
          <CardHeaderText>
            <CardHeaderTitle>
              <FormattedMessage
                {...messages.titleBenchmarkMaturityEstimations}
              />{' '}
              - {group.name}
            </CardHeaderTitle>
          </CardHeaderText>
        </CardHeader>
        <CardBody secondary>
          <div
            style={{
              maxWidth: '100%',
              overflow: 'auto'
            }}
          >
            {group.testItems.map((testItem, i) => {
              return (
                <Table
                  tableReport
                  compressedTable
                  extraClassNames={
                    'c-table--results-left-right u-margin-bottom-none '
                  }
                  key={uid(testItem, i)}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell evenHalf colSpan={2} empty={!testItem.result}>
                        {testItem.title}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        evenHalf
                        colSpan={2}
                        empty={!testItem.result}
                        style={{
                          flexDirection: [
                            Test.PERCENT_ADULT_HEIGHT_PREDICTION, //'face72bd-d260-44a3-b55c-3bae9e3d18dc', // 'Adult Height Prediction',
                            Test.YEARS_FROM_APHV //'7497420b-4a4f-4958-971e-f68aa41cbbe9' // 'Years from APHV (Maturity Offset)',
                          ].some(el => el === testItem.id)
                            ? 'column'
                            : 'row'
                        }}
                      >
                        {testItem.result &&
                          testItem.id !==
                            Test.PERCENT_ADULT_HEIGHT_PREDICTION &&
                          testItem.id !== Test.YEARS_FROM_APHV && (
                            <ProgressBarGroup fullWidth>
                              <ProgressBarLabelBlock
                                value={
                                  testItem.id ===
                                  Test.ADULT_HEIGHT_PREDICTION_M ? ( // 'Adult Height Prediction (M)',
                                    testItem.result[2][0]
                                  ) : testItem.id ===
                                    Test.PERCENT_ADULT_HEIGHT_PREDICTION ? (
                                    parseFloat(testItem.result[2][0]) > 100 ? (
                                      `100%`
                                    ) : (
                                      `${parseFloat(testItem.result[2][0])}%`
                                    )
                                  ) : (
                                    <FormattedMessage
                                      {...messages.labelUnit}
                                      values={{
                                        count: testItem.result[2][0],
                                        unit: testItem.unit
                                      }}
                                    />
                                  )
                                }
                              ></ProgressBarLabelBlock>
                            </ProgressBarGroup>
                          )}

                        {testItem.id === Test.YEARS_FROM_APHV && // 'Years from APHV (Maturity Offset)',
                          testItem.result && (
                            <Graphic
                              maturity
                              value={intl.formatMessage(messages.labelUnit, {
                                count: parseFloat(testItem.result[2][0]),
                                unit: testItem.unit
                              })}
                              position={
                                testItem.result[2][0] > 3
                                  ? 3
                                  : testItem.result[2][0] < -3
                                    ? -3
                                    : testItem.result[2][0]
                              }
                              extraClassNames={[
                                testItem.result[2][0] >= 2.745
                                  ? 'c-graphic--value-right'
                                  : testItem.result[2][0] <= -2.5
                                    ? 'c-graphic--value-left'
                                    : null
                              ]}
                            />
                          )}
                        {testItem.id === Test.PERCENT_ADULT_HEIGHT_PREDICTION && // 'Adult Height Prediction',
                          testItem.result && (
                            <Graphic
                              pah
                              value={intl.formatMessage(messages.labelUnit, {
                                count: parseFloat(testItem.result[2][0]),
                                unit: testItem.unit
                              })}
                              position={
                                testItem.result[2][0] >= 99.5
                                  ? 100.4
                                  : testItem.result[2][0] < 80.1
                                    ? 80.1
                                    : testItem.result[2][0]
                              }
                              extraClassNames={[
                                testItem.result[2][0] > 99
                                  ? 'c-graphic--value-right'
                                  : testItem.result[2][0] < 81.19
                                    ? 'c-graphic--value-left'
                                    : null
                              ]}
                            />
                          )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              );
            })}
          </div>
        </CardBody>
      </Card>
    );
  });
};

export default GrowthPredictionCard;
