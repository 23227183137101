import React from 'react';
import classNames from 'classnames';
import './Badge.scss';

interface BadgeProps {
  children?: React.ReactNode;
  /** We have two **variants**: `badge-dark`, or `badge-neutral` */
  variant?: 'badge-dark' | 'badge-neutral';
  larger?: boolean;
  lighterFont?: boolean;
  extraClassNames?: string;
  style?: object;
}

/** ### Where is it used?
 * - in `<Tabs />` is the default, without props,
 * - as `variant="badge-dark"` (used when a list item is "already added" into something)
 * - as `variant="badge-neutral"` (used in a title, when a session is "ended")
 * - and there is also a default `larger` version within the Users' usage view, under Settings
 * - and there is also a default `lighterFont` version used (mostly) in list items.
 *
 * #### Not in `<Tabs />`:
 * - in a `list-item-label` > `src/containers/pages/settings/benchmarks/Benchmarks.jsx`
 * - in the `panel-title` > `src/containers/pages/rehab/injury/Injury.jsx`
 * - in a `list-item-label` > `src/components/list/ResultList.jsx`:
 *   - Where in app? Only in **sportamundi** I assume > `IAmReportCard`, `ILikeReportCard`, `SapReportCard`, `SportsReportCard`
 *   - I don't think so. Also, where do this Cards are used? `IAmReportCard`, and `ILikeReportCard`, found... `SapReportCard` and `SportsReportCard`?
 *
 * #### Unmantained:
 * - Video Annotation: `src/containers/pages/videos/Videos.jsx`
 * */
const Badge = ({
  larger, // = false,
  lighterFont, // = false
  variant,

  children,
  extraClassNames,
  style
}: BadgeProps) => {
  return (
    <span
      className={classNames('c-badge', extraClassNames, {
        'c-badge--neutral': variant === 'badge-neutral', // DONE
        'c-badge--dark': variant === 'badge-dark', // DONE
        'c-badge--large': larger, // DONE
        'c-badge--light': lighterFont // DONE
      })}
      style={style}
    >
      {children}
    </span>
  );
};

export default Badge;
