import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
  withRouter
} from 'react-router-dom';
import Loader from './components/loader/Loader';
import { PageContent, PageLayout } from './components/page-layout';
import AppRoute from './routes/AppRoute';
import DrawerView from './containers/partials/drawer/DrawerView';
import { PageContentWrapper } from './components/page-layout/index';
import Header from './containers/partials/header/Header';
import {
  ROUTE_GROWTH,
  ROUTE_GROWTH_PREDICTION,
  ROUTE_LOADING,
  ROUTE_RESULTS,
  ROUTE_TESTS
} from 'routes/RouteList';
import { Panel } from './components/panel';
import Icon from './components/icon/Icon';
import BasicModal from './containers/partials/modals/BasicModal';
import { StoreContext } from 'index';
import { backController } from 'utils/back-controller';
import { Button } from './components/button';
import { useNetworkState } from 'react-use';
import { useNotificationQueue } from 'components/notification';
import messages from 'messages';
import LoadingPage from 'containers/pages/misc/LoadingPage';
import { BRAND_NAME } from 'constants.js';
import ErrorModal from 'containers/partials/modals/ErrorModal';
import { useStore } from 'stores/Store';
import { useClickOutsideListener } from '@patrissoljuns/react-click-outside';
import './_scss/_mzh.scss';

import { isMzhUserAgent } from 'utils/browser';

export const rootDiv = document.querySelector('#root');

const App = () => {
  const {
    authStore: { user, isLoggedIn },
    uiState
  } = useContext(StoreContext);
  const { push } = useHistory();
  const connected = useRef(true);
  let location = useLocation();
  const networkState = useNetworkState();
  const notification = useNotificationQueue();
  const intl = useIntl();

  const authorized = useStore(state => state.authorized);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const containerRef = useClickOutsideListener({
    onClickOutside: () => setDrawerOpen(false),
    events: ['mousedown']
  });
  const handleCloseDrawer = () => {
    return setDrawerOpen(false);
  };

  const [drawerType, setDrawerType] = useState('default');
  const [brand, setBrand] = useState(BRAND_NAME);

  const { appIsInSync } = uiState;

  useEffect(() => {
    switch (document.location.hostname) {
      case 'appfundis.constantbeta.be':
      case 'app.fundissport.com':
        setBrand('Fundis');
        document.title = 'Fundis';
        break;
      case 'app.sportamundi.com':
      case 'devapp.sportkompas.be':
        setBrand('Sportkompas');
        break;
      case 'app.hylyght.com':
        setBrand('Hylyght');
        break;
      case 'localhost':
        break;
      default:
        setBrand('Hylyght');
        break;
    }
  }, []);

  useEffect(() => {
    if (user?.personReady && !authorized) {
      uiState.setErrorModalData({
        message: { ...messages.errorModalMessageNotAuthorized },
        dismissHandler: () => {
          uiState.setErrorModalData(null);
          push('/');
        }
      });
    }
  }, [authorized, user]);

  const isLoadingPage = useRouteMatch(ROUTE_LOADING);
  let hasInterface =
    isLoggedIn &&
    location?.pathname &&
    !location?.pathname.startsWith(ROUTE_TESTS);
  //let isHomePage = isLoggedIn && pathname.startsWith(ROUTE_PACKAGES);
  let isResults =
    isLoggedIn &&
    location?.pathname &&
    location.pathname.startsWith(ROUTE_RESULTS);
  let isGrowthPrediction =
    isLoggedIn &&
    location?.pathname &&
    location.pathname.startsWith(ROUTE_GROWTH_PREDICTION);
  let isGrowthTracker =
    isLoggedIn &&
    location?.pathname &&
    location.pathname.startsWith(ROUTE_GROWTH);

  function cloneAttributes(target, source) {
    [...source.attributes].forEach(attr => {
      target.setAttribute(attr.nodeName, attr.nodeValue);
    });
  }

  useEffect(() => {
    if (brand === 'Fundis') {
      const selectors =
        '[rel*="stylesheet"], [rel*="icon"], [rel*="manifest"], [name="msapplication-config"]';

      [...document.querySelectorAll(selectors)].forEach(htmlElement => {
        let url = htmlElement.href;
        let content = htmlElement.content;

        if (
          (url && url.indexOf('fundis') === -1) ||
          (content && content.indexOf('fundis') === -1)
        ) {
          const newUrl = url
            ? url
                .replace('hylyght.com', 'fundissport.com')
                .replace('favicons-hylyght', 'favicons-fundis')
            : content
                .replace('hylyght.com', 'fundissport.com')
                .replace('favicons-hylyght', 'favicons-fundis');
          const htmlTag = document.createElement(
            htmlElement.tagName.toLowerCase()
          );

          cloneAttributes(htmlTag, htmlElement);

          htmlElement.remove();
          document.getElementsByTagName('head')[0].appendChild(htmlTag);

          if (url) {
            htmlTag.href = newUrl;
          } else {
            htmlTag.content = newUrl;
          }
        }
      });
    }
  });

  useEffect(() => {
    if (user) {
      if (user.isGSVOrg) {
        setDrawerType('gsv');
      } else if (user.isVitaleOrg) {
        setDrawerType('vitale');
      } else if (user.isAthlete()) {
        setDrawerType('athlete');
      } else {
        setDrawerType('default');
      }
    }
  }, [user, user?.config]);

  /*useEffect(() => {
    if (
      isLoggedIn &&
      window.Intercom === 'function' &&
      process.env.REACT_APP_INTERCOM_ENABLED === 'true'
    ) {
      window.Intercom('update');
    }
  }, [location.pathname, isLoggedIn]);*/

  // Watch connection
  useEffect(() => {
    if (!connected.current) {
      connected.current = true;
      notification.remove('connection_lost');
      notification.add('connection', {
        message: intl.formatMessage(messages.connectionBack),
        duration: 5000
      });
    }

    if (!networkState.online && connected.current) {
      connected.current = false;
      notification.add('connection_lost', {
        message: intl.formatMessage(messages.connectionLost),
        duration: 5000,
        level: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkState.online]);

  if (isLoadingPage)
    return <Route exact path={ROUTE_LOADING} component={LoadingPage} />;

  if (isMzhUserAgent()) {
    rootDiv.classList.add('ns-mzh');
  }

  return (
    <>
      {location?.pathname && location.pathname?.startsWith(ROUTE_TESTS) ? (
        <Fragment>
          <div
            style={{
              position: 'relative',
              height: '100dvh',
              width: '100vw'
            }}
          >
            {!appIsInSync && <Loader />}
            <AppRoute brand={brand} />
          </div>
        </Fragment>
      ) : (
        <PageLayout loginPage={!isLoggedIn} branding={brand}>
          <PageContent centered={!hasInterface}>
            {hasInterface && (
              <DrawerView
                type={drawerType}
                isOpenDrawer={drawerOpen}
                ref={containerRef}
                handleCloseDrawer={handleCloseDrawer}
              />
            )}

            {hasInterface && user && (
              <Header onClickOpenDrawer={() => setDrawerOpen(!drawerOpen)} />
            )}

            {hasInterface &&
              //!isHomePage &&
              !isResults &&
              !(isGrowthPrediction || isGrowthTracker) && (
                <Panel
                  navigator
                  onClick={() => backController.back(push)}
                  clickable={backController.hasBack()}
                >
                  {backController.hasBack() ? (
                    <Button small onClick={() => backController.back(push)}>
                      <Icon id="back" />
                    </Button>
                  ) : (
                    <Icon id="start-dot" />
                  )}
                </Panel>
              )}
            {/* TODO: Exercises/Programs && Exercises/Templates-edit > should also be === `horizontalScroller` */}
            <PageContentWrapper
              horizontalScroller={
                hasInterface &&
                //!isHomePage &&
                !isResults &&
                !(isGrowthPrediction || isGrowthTracker)
              }
              //isHomePage={isHomePage}
              isResults={isResults}
              isGrowthPrediction={isGrowthPrediction}
              isGrowthTracker={isGrowthTracker}
            >
              {!appIsInSync && <Loader />}

              <AppRoute brand={brand} />
              {uiState.modalData && (
                <BasicModal modalData={uiState.modalData} />
              )}
              {uiState.errorModalData && (
                <ErrorModal modalData={uiState.errorModalData} />
              )}
            </PageContentWrapper>
          </PageContent>
        </PageLayout>
      )}
    </>
  );
};

export default Sentry.withProfiler(withRouter(observer(App)));
