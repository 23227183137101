import appSyncConfig from './config/AppSync';
import { fetchAuthSession } from 'aws-amplify/auth';
import * as Sentry from '@sentry/react';
import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { PersonStatus } from 'constants.js';
import { useStore } from 'stores/Store';

const url = appSyncConfig.graphqlEndpoint;

const skipErrors = [
  'person_has_unconfirmed_login',
  'person_has_confirmed_login'
];

const getOperationData = ({ includeVariables, includeBody, operation }) => {
  const query = operation?.query;

  const definition = query?.definitions?.[0];

  const operationData = {
    name: operation?.operationName,
    type: definition?.['operation']
  };

  if (includeBody) {
    operationData.body = query?.loc?.source?.body || '';
  }

  if (includeVariables) {
    operationData.variables = JSON.stringify(operation?.variables || {});
  }

  return operationData;
};

const getResponseData = ({ response }) => {
  if (!response) {
    return null;
  }

  let responseData;

  try {
    responseData = JSON.stringify(response);
  } catch {
    responseData = response;
  }

  return responseData;
};

const errorLink = onError(
  ({ graphQLErrors, networkError, forward, operation, response }) => {
    if (!graphQLErrors) return;

    for (let err of graphQLErrors) {
      if (skipErrors.some(error => err.message.includes(error))) {
        continue;
      }

      if (err.errorType === 'UnauthorizedException') {
        const { getContext, setContext } = operation;
        const context = getContext();

        setContext({
          ...context,
          headers: {
            ...context?.headers,
            _needsRefresh: true
          }
        });

        return forward(operation);
      }

      console.error(`[GraphQL error]: Message:`, err.message, err.path);

      Sentry.withScope(scope => {
        if (operation) {
          const operationData = getOperationData({
            includeVariables: true,
            includeBody: true,
            operation
          });
          console.error(`[GraphQL error]: Operation data:`, operationData);
          scope.setExtra('operation', operationData);
        }

        if (response) {
          const responseData = getResponseData({
            response
          });
          scope.setExtra('response', responseData);
        }

        scope.setExtra('graphQLError', err);
        const errorTitle = 'GraphQL Error';

        Sentry.captureException(new Error(errorTitle));
      });
    }

    if (networkError) {
      Sentry.captureException(networkError);
    }
  }
);

export let appSyncClient;

const personFieldsAccountMeta = {
  read(account_meta = {}) {
    return typeof account_meta === 'string'
      ? JSON.parse(account_meta)
      : account_meta;
  }
};

const personFieldsIntegrations = {
  read(integrations = {}) {
    return typeof integrations === 'string'
      ? JSON.parse(integrations)
      : integrations;
  }
};

const personFieldsStatus = {
  read(status, { readField }) {
    let accountMeta = readField('account_meta');
    const email = readField('email');
    let meta = readField('meta');
    let athleteEmail = null;
    if (accountMeta && typeof accountMeta === 'string') {
      accountMeta = JSON.parse(accountMeta);
    }
    if (meta && typeof meta === 'string') {
      meta = JSON.parse(meta);
      athleteEmail = meta?.email ? (meta?.email).toString().trim() : '';
    }

    let accountStatus = PersonStatus.NONE;
    if (email && accountMeta?.status === 'CONFIRMED') {
      accountStatus = PersonStatus.USER;
    } else if (
      email &&
      (accountMeta?.status === 'FORCE_CHANGE_PASSWORD' ||
        accountMeta?.status === 'DELETED') &&
      accountMeta?.expires &&
      new Date(accountMeta.expires) < new Date()
    ) {
      accountStatus = PersonStatus.UNCONFIRMED;
    } else if (
      email &&
      accountMeta?.status === 'FORCE_CHANGE_PASSWORD' &&
      accountMeta?.expires &&
      new Date(accountMeta.expires) > new Date()
    ) {
      accountStatus = PersonStatus.PENDING;
    } else if (athleteEmail && !email) {
      accountStatus = PersonStatus.EMAIL;
    }
    return accountStatus;
  }
};

const authLink = setContext(async (_, previousContext) => {
  // Check if the token needs to be refreshed
  const { tokens } = await fetchAuthSession({
    forceRefresh: previousContext?.headers?._needsRefresh
  });

  // If we don't have any tokens then the user is not authorized
  if (!tokens) {
    useStore.setState({ authorized: false });
    return previousContext;
  }

  // Update the context with the new token
  return {
    ...previousContext,
    headers: {
      ...previousContext.headers,
      authorization: tokens.idToken?.toString()
    }
  };
});

export const setClient = async () => {
  const httpLink = new HttpLink({ uri: url });

  appSyncClient = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    shouldBatch: true,
    cache: new InMemoryCache({
      typePolicies: {
        Person: {
          fields: {
            account_meta: personFieldsAccountMeta,
            integrations: personFieldsIntegrations,
            status: personFieldsStatus
          }
        },
        PersonV2: {
          fields: {
            account_meta: personFieldsAccountMeta,
            integrations: personFieldsIntegrations,
            status: personFieldsStatus
          }
        },
        TestDataV2: {
          keyFields: ['id', 'resultBenchmarkId']
        },
        TestSetV2: {
          fields: {
            config: {
              // Merge the config fields together
              merge(existing = {}, incoming, { mergeObjects }) {
                if (incoming) {
                  return mergeObjects(
                    existing,
                    typeof incoming === 'string'
                      ? JSON.parse(incoming)
                      : incoming
                  );
                }
                return existing;
              }
            },
            meta: {
              read(meta = {}) {
                return typeof incoming === 'string' ? JSON.parse(meta) : meta;
              }
            }
          }
        },
        GrowthData: {
          fields: {
            testItemValues: {
              read(testItemValues = {}) {
                return typeof testItemValues === 'string'
                  ? JSON.parse(testItemValues)
                  : testItemValues;
              }
            },
            heightEvolutionData: {
              read(heightEvolutionData = {}) {
                return typeof heightEvolutionData === 'string'
                  ? JSON.parse(heightEvolutionData)
                  : heightEvolutionData;
              }
            },
            weightEvolutionData: {
              read(weightEvolutionData = {}) {
                return typeof weightEvolutionData === 'string'
                  ? JSON.parse(weightEvolutionData)
                  : weightEvolutionData;
              }
            }
          }
        }
      }
    })
  });

  return appSyncClient;
};
