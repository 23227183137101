import React, { useContext, useEffect, useState } from 'react';
import { ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE } from 'routes/RouteList';
import { FormattedMessage, useIntl } from 'react-intl';
import { StoreContext } from 'index';
import AddProgramModal from 'containers/pages/exercises/programs/AddProgramModal';
import Program from 'models/Program';
import { useSessionContext } from 'contexts/SessionContext';
import messages from 'messages';
import ReportRemarkInput from 'containers/partials/forms/ReportRemarkInput';
import ReportOptions from 'containers/partials/forms/ReportOptions';
import { uid } from 'react-uid';
import GrowthPredictionCard from 'containers/partials/cards/ReportCards/GrowthPredictionCard';
import PreventionCategoryCard from 'containers/partials/cards/ReportCards/PreventionCategoryCard';
import Loader from 'components/loader/Loader';
import SportsReportCard from 'containers/partials/cards/ReportCards/SportsReportCard';
import { useTestDataContext } from 'contexts/TestDataContext';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { TabPanelHead } from 'components/tabs';
import TimeNavigator from 'containers/partials/time-navigator/TimeNavigator';
import { useHistory, useParams } from 'react-router-dom';
import { useSetState } from 'react-use';
import EmptyReportCard from 'containers/partials/cards/ReportCards/EmptyReportCard';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import Modal from 'components/modal/Modal';
import SidePanelChartCard from 'containers/partials/cards/SidePanelChartCard';

const ReportCard = ({ compressed, rootPath, personInjury }) => {
  const intl = useIntl();
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const person = useEntityContext(s => s.person);
  const { session, options, sessionLoading } = useSessionContext();
  const {
    testDataId,
    testData,
    groupedResults,
    activeDate,
    setNewActiveDate,
    hasResults,
    sidePanelData,
    onCloseSidePanel
  } = useTestDataContext();
  const { entityId, sporterId } = useParams();
  const [modalData, setModalData] = useState(null);
  const [state, setState] = useSetState({
    previousDate: undefined,
    nextDate: undefined,
    selectedDate: undefined,
    highlightDates: []
  });

  useEffect(() => {
    if (session?.id) {
      if (session.isRecurring) {
        const testedDates = session.getTestedDates(sporterId);
        if (activeDate) {
          setState({
            selectedDate: testData.testDataDate ?? activeDate,
            highlightDates: { finished: testedDates.finished, unfinished: [] },
            previousDate: session.getPreviousTestedDate(
              testData.testDataDate ?? activeDate,
              sporterId,
              true
            ),
            nextDate: session.getNextTestedDate(
              testData.testDataDate ?? activeDate,
              sporterId,
              true
            )
          });
        }
      }
    }
  }, [session, testData, activeDate]);

  if (sessionLoading || !testDataId || groupedResults.length === 0)
    return <Loader />;

  if (session.groupedTests.length === 0) {
    return (
      <Card secondary>
        <CardBody extraClassNames="u-margin-bottom-none u-padding-vertical-huge">
          <Message emptyState icon="test">
            <MessageText>
              <FormattedMessage {...messages.emptySessionForAthlete} />
            </MessageText>
          </Message>
        </CardBody>
      </Card>
    );
  }

  const onChangeDate = async (date, testDataId) => {
    if (testDataId) {
      push(`${rootPath}/report/${testDataId}`);
      return;
    }
    setNewActiveDate(date);
  };

  return (
    <>
      <div
        className="u-margin-top-medium u-padding-bottom-medium"
        key={uid(testDataId)}
      >
        {session.isRecurring && (
          <TabPanelHead sticky>
            <TimeNavigator
              selectedDate={state.selectedDate}
              highlightDates={state.highlightDates}
              previousDate={state.previousDate}
              nextDate={state.nextDate}
              onChange={onChangeDate}
              path={`${rootPath}/report`}
              minDate={session.startdate}
              maxDate={session.enddate}
            />
          </TabPanelHead>
        )}
        {user.isHylyghtOrg && !user.isAthlete() && (
          <ReportOptions
            key={uid('reportCardOptions')}
            setModalData={setModalData}
            showCreateProgramButton={session.isPrevention || session.isRehab}
          />
        )}
        {!user.isAthlete() && session.isTestSetV2 && (
          <ReportRemarkInput entityId={entityId} />
        )}

        {!hasResults() ? (
          <EmptyReportCard />
        ) : (
          <>
            {Object.keys(groupedResults).map((category, i) => {
              const results = groupedResults[category];

              // const hasTestItems = results.some(t => t.testItems.length > 0);
              // if (!hasTestItems) return <></>;
              if (session.isPrevention) {
                return (
                  <PreventionCategoryCard
                    isPrev={session.isPrevention}
                    key={uid(category, i)}
                    category={category.toLowerCase()}
                    results={results}
                    compressedTable={compressed}
                    isAthlete={user.isAthlete()}
                    sides={personInjury ? personInjury.sides : []}
                    options={options}
                  />
                );
              } else {
                switch (category.toLowerCase()) {
                  case 'b_growthprediction':
                    return (
                      <GrowthPredictionCard
                        key={uid(category, i)}
                        category={'growthprediction'}
                        results={results}
                      />
                    );
                  default:
                    return (
                      <PreventionCategoryCard
                        isPrev={session.isPrevention}
                        key={uid(category, i)}
                        category={category.toLowerCase()}
                        results={results}
                        compressedTable={compressed}
                        isAthlete={user.isAthlete()}
                        sides={personInjury ? personInjury.sides : []}
                        options={options}
                      />
                    );
                }
              }
            })}
            {testData?.sports?.length > 0 && (
              <SportsReportCard sports={testData.sports} />
            )}
          </>
        )}

        {!user.isAthlete() && modalData && (
          <AddProgramModal
            entityId={entityId}
            destiny={{
              path: ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE,
              variables: {
                entityId,
                sessionsTab: 'sessions',
                sessionId: session.id,
                sporterId: person.id
              }
            }}
            program={
              new Program({
                entityId,
                title: intl.formatMessage(messages.titleNewProgram, {
                  person: `${person.firstname} ${person.lastname}`
                })
              })
            }
            persons={[person]}
            onClose={() => setModalData(null)}
          />
        )}
      </div>
      {sidePanelData && (
        <Modal cardPanel isOpen={true} onClose={onCloseSidePanel}>
          <SidePanelChartCard {...sidePanelData} />
        </Modal>
      )}
    </>
  );
};

export default ReportCard;
