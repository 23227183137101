import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardFooter } from 'components/card';
import { Button, ButtonsGroup } from 'components/button';
import { ROUTE_FORGOT } from 'routes/RouteList';
import messages from 'messages';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import InputErrorMessage from 'components/input/InputErrorMessage';
import FieldInput from 'components/input/FieldInput';
import { StoreContext } from 'index';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

const Login = ({ brand }) => {
  const { authStore } = useContext(StoreContext);
  const { push } = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const [loginValues, setLoginValues] = useState({
    email: '',
    password: ''
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = atob(searchParams.get('email'));
    if (searchParams.has('email')) {
      setLoginValues({ ...loginValues, email });
    }
  }, [location]);

  const onSubmitHandler = async ({ email, password }) => {
    const result = await authStore.doLogin(email, password);
    if (result?.error) {
      setErrorMessage(result.error.message);
    }
  };

  const onForgotClick = e => {
    e.preventDefault();
    push(ROUTE_FORGOT);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required()
  });

  return (
    <Card extraClassNames="c-card--primary c-card--login">
      <CardHeader primary>
        <img
          className="c-logo-img"
          src={require(
            `assets/images/logo-${brand.toLowerCase() === 'sportkompas' ? 'sport-vlaanderen.png' : brand.toLowerCase() + '.svg'}`
          )}
          alt={`${brand} logo`}
        />
        <CardHeaderTitle>
          <FormattedMessage {...messages.loginTitle} />
        </CardHeaderTitle>
        <CardHeaderSubtitle>
          <FormattedMessage {...messages[`loginMessage${brand}`]} />
        </CardHeaderSubtitle>
      </CardHeader>
      <Formik
        initialValues={loginValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {({ touched, errors, isValid, dirty }) => {
          return (
            <Form noValidate>
              <CardBody primary>
                <FieldInput
                  id="email"
                  name={'email'}
                  type="email"
                  tabIndex="1"
                  errors={errors}
                  touched={touched}
                  autoComplete={'on'}
                >
                  <FormattedMessage {...messages.loginUsername} />
                </FieldInput>
                <FieldInput
                  id="password"
                  name={'password'}
                  type="password"
                  tabIndex="1"
                  errors={errors}
                  touched={touched}
                  autoComplete={'on'}
                >
                  <Button
                    tabIndex="0"
                    miniDefaultText
                    onClick={e => onForgotClick(e)}
                    dataQa={`forgot-pass-button`}
                  >
                    <FormattedMessage {...messages.loginForgotPasswordButton} />
                  </Button>
                  <FormattedMessage {...messages.loginPassword} />
                </FieldInput>
                {errorMessage && (
                  <InputErrorMessage>
                    {errorMessage === 'api-error' ? (
                      <FormattedMessage {...messages.apiError} />
                    ) : (
                      errorMessage
                    )}
                  </InputErrorMessage>
                )}
              </CardBody>
              <CardFooter primary>
                <ButtonsGroup>
                  <Button
                    primary
                    type="submit"
                    tabIndex="1"
                    disabled={!(isValid && dirty)}
                    dataQa={`submit-login`}
                  >
                    <FormattedMessage {...messages.loginSubmitButton} />
                  </Button>
                </ButtonsGroup>
              </CardFooter>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default Login;
