import React from 'react';
import classNames from 'classnames';

class CardBodyTools extends React.Component {
  render() {
    const { extraClassNames, ...props } = this.props;

    return (
      <div
        className={classNames('c-card__body--tools', extraClassNames, {})}
        style={props.style}
      >
        {props.children}
      </div>
    );
  }
}

CardBodyTools.propTypes = {};

export default CardBodyTools;
