import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useMemo
} from 'react';
import * as Sentry from '@sentry/react';
import {
  GrowthTrackerEstimationMethods,
  idToNameTestItem,
  Test
} from 'constants.js';
import { useHistory } from 'react-router-dom';
import { differenceInWeeks, format } from 'date-fns';
import { QUERY_GET_GROWTH_DATA_OF_GROUP } from 'services/aws/growthtracker-query';
import { useLazyQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { QUERY_GET_TESTDATA_HISTORY } from 'services/aws/session-query';
import { useIntl } from 'react-intl';
import messages from 'messages';

export const GrowthPanelContext = createContext({});

const columnHelper = createColumnHelper();

const formatCell = data => {
  return data ? `${data.value} ${data.unit}` : '-';
};

const sortByValue = (rowA, rowB, _columnId) => {
  const valueA = Number(rowA.original[_columnId].value);
  const valueB = Number(rowB.original[_columnId].value);

  return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
};

const sortByFirstValue = (rowA, rowB, _columnId) => {
  const valueA = Number(rowA.original[_columnId].value.split(' - ')[0]);
  const valueB = Number(rowB.original[_columnId].value.split(' - ')[0]);

  return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
};

const sortByAlertLevel = (rowA, rowB, _columnId) => {
  const valueA = Number(rowA.original[_columnId].alertLevel);
  const valueB = Number(rowB.original[_columnId].alertLevel);

  return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
};

const sortByArrayValue = (rowA, rowB, _columnId) => {
  const valueA = Number(rowA.original[_columnId].value[1]);
  const valueB = Number(rowB.original[_columnId].value[1]);

  return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
};

const GrowthTrackerColumns = intl => {
  return [
    columnHelper.accessor('person', {
      header: () => '',
      accessorFn: row => `${row.person.firstname} ${row.person.lastname}`,
      size: 200,
      minSize: 150,
      sortingFn: 'alphanumeric',
      enableHiding: false
    }),
    columnHelper.accessor('preciseAge', {
      header: () => (
        <>
          {intl.formatMessage(messages.growthPredictionChronoAge, {
            isShort: true,
            br: () => <br />
          })}
        </>
      ),
      cell: props => formatCell(props),
      size: 96,
      sortingFn: sortByValue
    }),
    columnHelper.accessor('bodyHeight', {
      header: () => (
        <>
          {intl.formatMessage(messages.growthPredictionBodyHeight, {
            isShort: true,
            br: () => <br />
          })}
        </>
      ),
      cell: props => formatCell(props),
      size: 96,
      sortingFn: sortByValue
    }),
    columnHelper.accessor('bodyWeight', {
      id: 'bodyWeight',
      header: () => (
        <>
          {intl.formatMessage(messages.growthPredictionBodyWeight, {
            isShort: true,
            br: () => <br />
          })}
        </>
      ),
      cell: props => formatCell(props),
      size: 96,
      sortingFn: sortByValue
    }),
    columnHelper.accessor('adultHeightPrediction', {
      id: 'adultHeightPrediction',
      header: () => (
        <>
          {intl.formatMessage(messages.growthPredictionAdultHPredict, {
            isShort: true,
            br: () => <br />
          })}
        </>
      ),
      cell: props => formatCell(props),
      size: 112,
      sortingFn: sortByValue,
      meta: {
        longLabel: () => (
          <>
            {intl.formatMessage(messages.growthPredictionAdultHPredict, {
              isShort: false,
              br: () => <br />
            })}
          </>
        )
      }
    }),
    columnHelper.accessor('pah90Ci', {
      id: 'pah90Ci',
      header: () => (
        <>
          {intl.formatMessage(messages.growthPredictionPAH90CI, {
            isShort: true,
            br: () => <br />
          })}
        </>
      ),
      cell: props => formatCell(props),
      size: 112,
      sortingFn: sortByFirstValue
    }),
    columnHelper.accessor('predictedAdultHeight', {
      id: 'predictedAdultHeight',
      header: () => (
        <>
          {intl.formatMessage(messages.growthPredictionPredictedAdultHeightKR, {
            isShort: true,
            br: () => <br />
          })}
        </>
      ),
      cell: props => formatCell(props),
      size: 112,
      sortingFn: sortByValue,
      meta: {
        longLabel: () => (
          <>
            {intl.formatMessage(
              messages.growthPredictionPredictedAdultHeightKR,
              {
                isShort: false
              }
            )}
          </>
        )
      }
    }),
    columnHelper.accessor('growthPhase', {
      id: 'growthPhase',
      header: () => (
        <>
          {intl.formatMessage(messages.growthPredictionMaturityStatus, {
            isShort: true,
            br: () => <br />
          })}
        </>
      ),
      cell: props => formatCell(props),
      size: 96,
      sortingFn: sortByAlertLevel,
      meta: {
        longLabel: () => (
          <>
            {intl.formatMessage(messages.growthPredictionMaturityStatus, {
              isShort: false
            })}
          </>
        )
      }
    }),
    columnHelper.accessor('developmentZScore', {
      id: 'developmentZScore',
      header: () => (
        <>
          {intl.formatMessage(messages.growthPredictionMaturationTiming, {
            isShort: true,
            br: () => <br />
          })}
        </>
      ),
      cell: props => formatCell(props),
      size: 112,
      sortingFn: sortByArrayValue,
      meta: {
        longLabel: () => (
          <>
            {intl.formatMessage(messages.growthPredictionMaturationTiming, {
              isShort: false
            })}
          </>
        )
      }
    }),
    columnHelper.accessor('heightVelocity', {
      id: 'heightVelocity',
      header: () => (
        <>
          {intl.formatMessage(messages.growthPredictionGrowthVelocity, {
            isShort: true,
            br: () => <br />
          })}
        </>
      ),
      cell: props => formatCell(props),
      size: 128,
      sortingFn: sortByValue,
      meta: {
        longLabel: () => (
          <>
            {intl.formatMessage(messages.growthPredictionGrowthVelocity, {
              isShort: false
            })}
          </>
        )
      }
    }),
    columnHelper.accessor('weightVelocity', {
      id: 'weightVelocity',
      header: () => (
        <>
          {intl.formatMessage(messages.growthPredictionWeightVelocity, {
            br: () => <br />
          })}
        </>
      ),
      cell: props => formatCell(props),
      size: 128,
      sortingFn: sortByValue
    }),
    columnHelper.accessor('bioAge', {
      id: 'bioAge',
      header: () => (
        <>
          {intl.formatMessage(messages.growthPredictionBioAge, {
            br: () => <br />
          })}
        </>
      ),
      cell: props => formatCell(props),
      size: 96,
      sortingFn: sortByValue
    }),
    columnHelper.accessor('chronoVsBio', {
      id: 'chronoVsBio',
      header: () => (
        <>
          {intl.formatMessage(messages.growthPredictionChronoVsBioAge, {
            isShort: true,
            br: () => <br />
          })}
        </>
      ),
      cell: props => formatCell(props),
      size: 96,
      sortingFn: sortByValue,
      meta: {
        longLabel: () => (
          <>
            {intl.formatMessage(messages.growthPredictionChronoVsBioAge, {
              isShort: false
            })}
          </>
        )
      }
    })
  ];
};

const getAlertOldData = data => {
  return (
    data.heightEvolutionData.length > 0 &&
    (differenceInWeeks(
      new Date(),
      new Date(
        data.heightEvolutionData[
          data.heightEvolutionData.length - 1
        ].testDateTime
      )
    ) > 10 ||
      differenceInWeeks(
        new Date(),
        new Date(
          data.weightEvolutionData[
            data.weightEvolutionData.length - 1
          ].testDateTime
        )
      ) > 10)
  );
};

const getPAHLevel = growthPhase => {
  switch (true) {
    case growthPhase.toLowerCase().indexOf('pre') >= 0:
      return 3;

    case growthPhase.toLowerCase().indexOf('start') >= 0:
      return 1;

    case growthPhase.toLowerCase().indexOf('circa') >= 0:
      return 0;

    case growthPhase.toLowerCase().indexOf('post') >= 0:
      return 4;

    default:
      return 2;
  }
};

const getValue = testItemValues => {
  return testItemValues && Object.keys(testItemValues).length > 0
    ? testItemValues[0]
    : false;
};

const getRowData = data => {
  return {
    preciseAge: {
      value: data.preciseAge ? data.preciseAge : false,
      unit: 'yearShort'
    },
    bodyHeight: {
      value: getValue(data.testItemValues[Test.BODY_HEIGHT]),
      unit: 'cm',
      testItemId: Test.BODY_HEIGHT
    },
    bodyWeight: {
      value: getValue(data.testItemValues[Test.BODY_WEIGHT]),
      unit: 'kg',
      testItemId: Test.BODY_WEIGHT
    },
    adultHeightPrediction: {
      value: getValue(
        data.testItemValues[Test.ADULT_HEIGHT_PREDICTION_K_R] //'5737716d-7eb3-4724-85d2-7baa4652ae93'
      ),
      unit: 'cm'
    },
    pah90Ci: {
      value: getValue(
        data.testItemValues[Test.ADULT_HEIGHT_PREDICTION_CI] //'f7529cf9-ba05-4570-bedd-6213ee3b6ec8'
      ),
      unit: 'cm'
    },
    predictedAdultHeight: {
      value: getValue(
        data.testItemValues[Test.PERCENT_ADULT_HEIGHT_PREDICTION]
      ),
      unit: '%',
      alertLevel: !!getValue(data.testItemValues[Test.GROWTH_PHASE])
        ? getPAHLevel(data.testItemValues[Test.GROWTH_PHASE][0])
        : false,
      iconWarning: !!getValue(data.testItemValues[Test.GROWTH_PHASE])
        ? getPAHLevel(data.testItemValues[Test.GROWTH_PHASE][0]) <= 1
        : false
    },
    growthPhase: {
      value: getValue(data.testItemValues[Test.GROWTH_PHASE]),
      unit: '',
      alertLevel: !!getValue(data.testItemValues[Test.GROWTH_PHASE])
        ? getPAHLevel(data.testItemValues[Test.GROWTH_PHASE][0])
        : false,
      iconWarning: !!getValue(data.testItemValues[Test.GROWTH_PHASE])
        ? getPAHLevel(data.testItemValues[Test.GROWTH_PHASE][0]) <= 1
        : false
    },
    developmentZScore: {
      value: [
        getValue(data.testItemValues[Test.MATURATION_TIMING]),
        !!getValue(data.testItemValues[Test.DEVELOPMENT_Z_SCORE])
          ? getValue(data.testItemValues[Test.DEVELOPMENT_Z_SCORE]).toFixed(2)
          : false
      ],

      unit: ''
    },
    heightVelocity: {
      value:
        data.heightEvolutionData?.length > 0 &&
        data.heightEvolutionData[data.heightEvolutionData.length - 1]
          ?.growthYear,
      unit: 'cmYearShort',
      alert:
        data.heightEvolutionData?.length > 0 &&
        data.heightEvolutionData[data.heightEvolutionData.length - 1]
          ?.testDateTime &&
        differenceInWeeks(
          new Date(),
          new Date(
            data.heightEvolutionData[
              data.heightEvolutionData.length - 1
            ].testDateTime
          )
        ) > 10,
      alertLevel:
        data.heightEvolutionData?.length > 0 &&
        data.heightEvolutionData[data.heightEvolutionData.length - 1]
          ?.alertLevel,
      iconWarning:
        data.heightEvolutionData?.length > 0 &&
        data.heightEvolutionData[data.heightEvolutionData.length - 1]
          ?.alertLevel < 2,
      testItemId: 'heightEvolution'
    },
    weightVelocity: {
      value:
        data.weightEvolutionData?.length > 0 &&
        data.weightEvolutionData[data.weightEvolutionData.length - 1]
          ?.growthYear,
      unit: 'kgYearShort',
      alert:
        data.weightEvolutionData?.length > 0 &&
        data.weightEvolutionData[data.weightEvolutionData.length - 1]
          ?.testDateTime &&
        differenceInWeeks(
          new Date(),
          new Date(
            data.weightEvolutionData[
              data.weightEvolutionData.length - 1
            ].testDateTime
          )
        ) > 10,
      alertLevel:
        data.weightEvolutionData?.length > 0 &&
        data.weightEvolutionData[data.weightEvolutionData.length - 1]
          ?.alertLevel,
      iconWarning:
        data.weightEvolutionData?.length > 0 &&
        data.weightEvolutionData[data.weightEvolutionData.length - 1]
          ?.alertLevel < 2,
      testItemId: 'weightEvolution'
    },
    bioAge: {
      value: getValue(data.testItemValues[Test.BIO_AGE]),
      unit: 'yearShort'
    },
    chronoVsBio: {
      value: getValue(data.testItemValues[Test.CHRONO_VS_BIO_AGE]),
      unit: 'yearLong'
    }
  };
};

const GrowthPanelContextProvider = ({ groupId, children }) => {
  const intl = useIntl();
  const location = useHistory();

  const usersArray = useRef([]);

  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);
  const [sorting, setSorting] = useState([
    {
      id: 'person',
      desc: false
    }
  ]);
  const [columnOrder, setColumnOrder] = useState([
    'person',
    'heightVelocity', // growth velocity
    'predictedAdultHeight', // % PAH
    'weightVelocity', // weight velocity
    'preciseAge', // chrono age
    'bioAge', // bio age
    'chronoVsBio', // chrono vs bio
    'developmentZScore', // maturation timing
    'growthPhase', // maturation status
    'bodyHeight', // body height
    'adultHeightPrediction', // AH prediction
    'bodyWeight', // body weight
    'pah90Ci' // pah 90%
  ]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [sidePanelData, setSidePanelData] = useState(null);
  const [error, setError] = useState(null);
  const [method, setMethod] = useState(
    GrowthTrackerEstimationMethods.KHAMIS_ROCHE
  );
  const [showColumnVisibilityModal, setShowColumnVisibilityModal] =
    useState(false);

  const [fetchGrowthDataByGroup, { loading, error: fetchError }] = useLazyQuery(
    QUERY_GET_GROWTH_DATA_OF_GROUP
  );

  const [getTestDataHistory, { loading: loadingTestDataHistory }] =
    useLazyQuery(QUERY_GET_TESTDATA_HISTORY);

  const growthtrackerColumns = useMemo(() => GrowthTrackerColumns(intl), []);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchGrowthDataByGroup({
        variables: {
          entityId: groupId
        },
        fetchPolicy: 'network-only'
      });

      if (data?.getGrowthDataOfGroup2) {
        const growthData = data.getGrowthDataOfGroup2;

        console.log('growthData', growthData);

        usersArray.current = growthData.map(d => {
          return {
            id: d.person.id,
            person: d.person,
            alertOldData: getAlertOldData(d),
            ...getRowData(d)
          };
        });

        setRows(usersArray.current);
        setCols(growthtrackerColumns);
      }
    };

    if (groupId) {
      fetchData().catch(error => {
        Sentry.captureException(error);
      });
    }
  }, [groupId, location]);

  const onChangeMethod = method => {
    setMethod(method);
  };

  const loadTestDataHistory = async ({ person, testItemId, unit }) => {
    const result = await getTestDataHistory({
      variables: {
        personId: person.id,
        testItemId: testItemId
      }
    });

    if (result.data?.getTestDataHistory) {
      if (result.data.getTestDataHistory.length === 0) {
        return null;
      }
      const data = result.data.getTestDataHistory.map(d => {
        const value = JSON.parse(d.values).map(v =>
          v ? Number(parseFloat(v).toPrecision(4)) : v
        )[0];
        const dateValue = format(new Date(d.testDateTime), 'yyyy-LL-dd');
        const dateString = format(new Date(d.testDateTime), 'dd/LL/yyyy');
        return {
          x: dateValue,
          y: value,
          result: value,
          date: dateString,
          sortDate: dateValue,
          unit: unit,
          inputType: 'device'
        };
      });
      return data;
    }

    return null;
  };

  const onClickCell = async (cell, testItemId, unitValue) => {
    const person = cell.row.original.person;

    const dataObj = {
      dataSrc: loadTestDataHistory,
      dataSrcProps: { person, testItemId, unit: unitValue },
      title: `${person.firstname} ${person.lastname}`,
      subTitle: idToNameTestItem[testItemId],
      testItemId,
      person,
      unit: unitValue,
      laterality: '2',
      chartData: {
        min: 0,
        max: 10,
        avg: null,
        benchmarkType: 'default',
        data: null
      }
    };

    setSidePanelData(dataObj);
  };

  const onCloseSidePanel = () => setSidePanelData(null);

  return (
    <GrowthPanelContext.Provider
      value={{
        loading,
        cols,
        rows,
        columnVisibility,
        setColumnVisibility,
        sorting,
        setSorting,
        columnOrder,
        onClickCell,
        sidePanelData,
        onCloseSidePanel,
        error,
        method,
        onChangeMethod,
        showColumnVisibilityModal,
        setShowColumnVisibilityModal
      }}
    >
      {children}
    </GrowthPanelContext.Provider>
  );
};

function useGrowthPanelContext() {
  const context = useContext(GrowthPanelContext);
  if (context === undefined) {
    throw new Error(
      'The GrowthPanelContext hook must be used within a GrowthPanelContext.Provider'
    );
  }
  return context;
}

export { GrowthPanelContextProvider, useGrowthPanelContext };
