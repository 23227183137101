import React, { createContext, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useSessionContext } from 'contexts/SessionContext';
import { FinishedState } from 'enums';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  MUTATION_START_TEST_SESSION,
  MUTATION_UPDATE_TEST_DATA,
  QUERY_GET_TESTDATA,
  QUERY_GET_TESTDATA_HISTORY
} from 'services/aws/session-query';
import TestData from 'models/TestData';
import { groupTestResults } from 'utils/tests';
import { useNotificationQueue } from 'components/notification';
import { useIntl } from 'react-intl';
import messages from 'messages.js';
import { getToday } from 'utils/date';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { StoreContext } from 'index';
import structuredClone from '@ungap/structured-clone';
import { Test } from 'constants.js';

export const TestDataContext = createContext();

const TestDataProvider = ({ children }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  const { entityId, testDataId, sporterId } = useParams();
  const { session, refetch, options } = useSessionContext();
  const [testData, setTestData] = useState(new TestData({}));
  const [groupedResults, setGroupedResults] = useState([]);
  const [formData, setFormData] = useState({});
  const [formIsUpdated, setFormIsUpdated] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const notification = useNotificationQueue();
  const [activeDate, setActiveDate] = useState();
  const [sidePanelData, setSidePanelData] = useState(null);

  const [
    getTestData,
    { data, loading: testDataLoading, refetch: refetchTestData }
  ] = useLazyQuery(QUERY_GET_TESTDATA);

  const [getTestDataHistory, { loading: loadingTestDataHistory }] =
    useLazyQuery(QUERY_GET_TESTDATA_HISTORY);

  const [updateTestData] = useMutation(MUTATION_UPDATE_TEST_DATA);
  const [startTestSession] = useMutation(MUTATION_START_TEST_SESSION);

  useEffect(() => {
    if (data?.getTestDataV2) {
      const testDataResult = new TestData(data.getTestDataV2);
      const groupedResults =
        Array.isArray(testDataResult.result) &&
        groupTestResults(filterGrowthPredictionData(testDataResult.result));
      setGroupedResults(groupedResults);

      const sessionFormData = session.getFormData();
      // merge with the session formData
      setFormData({ ...sessionFormData, ...testDataResult.formData });
      setActiveDate(testDataResult.testDataDate);
      setTestData(testDataResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const createTestData = async date => {
    const testData = await startTestSession({
      variables: {
        personId: sporterId ?? user.id,
        testSessionId: session.id,
        finished: FinishedState.STARTED,
        testDataDate: date ?? getToday()
      }
    });

    if (testData?.data?.addTestData) {
      await refetch({ testSessionId: session.id });
      return testData.data.addTestData.id;
    }

    return null;
  };

  const fetchTestData = async (sessionId, testDataId) => {
    const variables = {
      entityId,
      testDataId,
      testSessionId: session.id
    };
    if (options.benchmark && !(session.isRehab || session.isPrevention)) {
      variables.benchmarkId = options.benchmark;
    }
    if (entityId && sessionId && testDataId) {
      await getTestData({
        variables,
        fetchPolicy: 'no-cache'
      });
    }
  };

  const findTestDataByDate = async testDataDate => {
    if (session?.id && testDataDate) {
      setActiveDate(testDataDate);
      const dateFormatted = format(new Date(testDataDate), 'yyyy-LL-dd');
      const testData = session.testData.find(
        testData =>
          testData.testDataDate === dateFormatted &&
          testData.personId === sporterId
      );

      if (testData) {
        return testData;
      } else {
        return false;
      }
    }
  };

  const setNewActiveDate = async testDataDate => {
    if (testDataDate !== activeDate) {
      // setNewSelectedDate(testDataDate);
      setActiveDate(testDataDate);
    }
  };

  const unsetAllData = () => {
    setFormData({});
    setTestData(new TestData({}));
    setFormIsUpdated(false);
  };

  const updateFormData = (testId, testItem, testDataId, data) => {
    const tempData = unNullifyData(data);
    setFormData({ ...tempData });
  };

  // const submitData = (testId, testItem, testDataId, data) => {
  const submitData = async testDataId => {
    let formValues = structuredClone(formData);

    Object.keys(formValues).forEach(key => {
      Object.keys(formValues[key]).forEach(laterality => {
        if (Array.isArray(formValues[key][laterality])) {
          formValues[key][laterality] = [
            ...formData[key][laterality].map(v => {
              return v !== '' && typeof v !== 'object' && !isNaN(Number(v))
                ? Number(v)
                : null;
            })
          ];
        } else {
          formValues[key][laterality] = [''];
        }
      });

      const testItemValue = {
        [key]: {
          [key]: {
            ...formValues[key]
          }
        }
      };
      formValues = { ...formValues, ...testItemValue };
    });

    return await updateTestData({
      variables: {
        id: testDataId,
        data: JSON.stringify(formValues),
        finished: FinishedState.STARTED
      }
    })
      .then(result => {
        setFormIsDirty(false);
        setFormIsUpdated(true);
        notification.add(result.data.editTestData.id, {
          message: intl.formatMessage(messages.messageTestdataSaved)
        });
        return result;
      })
      .catch(e => {
        notification.add(`testDataError${testDataId}`, {
          message: intl.formatMessage(messages.messageTestdataSaveError),
          level: 'error'
        });
        Sentry.captureException(e);
        return e;
      });
  };

  const hasResults = () => {
    if (testData?.id) {
      return testData?.finishedPercentage > 0;
    } else {
      return false;
      // TODO this check is wrong, not sure why it was here, finishedPercentage is not a correct check
      /*
      const sessionTestData = session.getLastTestData(sporterId);
      if (sessionTestData?.id) {
        return sessionTestData?.finishedPercentage > 0;
      } else {
        return false;
      }
      */
    }
  };

  const loadTestDataHistory = async ({ person, testItem }) => {
    const result = await getTestDataHistory({
      variables: {
        personId: person.id,
        testItemId: testItem.id
      }
    });

    if (result.data?.getTestDataHistory) {
      if (result.data.getTestDataHistory.length === 0) {
        return null;
      }

      const data = result.data.getTestDataHistory.map(d => {
        const values = JSON.parse(d.values);

        const value = values.map(v => {
          if (testItem.input_type === 'select_with_options') {
            const option = testItem.input_type_parameters.options.find(
              o => o.value === v
            );
            return option ? option : v;
          } else {
            return v ? Number(parseFloat(v).toFixed(testItem.showDecimals)) : v;
          }
        });

        const dateValue = format(new Date(d.testDateTime), 'yyyy-LL-dd');
        const dateString = format(new Date(d.testDateTime), 'dd/LL/yyyy');
        return {
          x: dateValue,
          y:
            testItem.input_type === 'select_with_options'
              ? value[0].value
              : value[0],
          result: value[0],
          date: dateString,
          sortDate: dateValue,
          unit: testItem.unit,
          laterality: d.type,
          decimals: testItem.showDecimals,
          inputType: testItem.input_type
        };
      });

      return data;
    }

    return null;
  };

  const onClickResult = ({ testItem, laterality, person }) => {
    const dataObj = {
      dataSrc: loadTestDataHistory,
      dataSrcProps: { person, testItem, laterality },
      title: person.fullName,
      subTitle: testItem.title,
      testItemId: testItem.id,
      person: person,
      unit: testItem.unit,
      laterality,
      chartData: {
        min: 0,
        max: 10,
        avg: null,
        benchmarkType: 'default',
        data: null
      }
    };
    setSidePanelData(dataObj);
  };

  const onCloseSidePanel = () => setSidePanelData(null);

  return (
    <TestDataContext.Provider
      value={{
        testDataId,
        testData,
        hasResults,
        testDataLoading,
        formData,
        groupedResults,
        fetchTestData,
        refetchTestData,
        updateFormData,
        submitData,
        createTestData,
        formIsUpdated,
        formIsDirty,
        setFormIsDirty,
        activeDate,
        setActiveDate,
        findTestDataByDate,
        setNewActiveDate,
        unsetAllData,
        onClickResult,
        sidePanelData,
        onCloseSidePanel
      }}
    >
      {children}
    </TestDataContext.Provider>
  );
};

function useTestDataContext() {
  const context = useContext(TestDataContext);
  if (context === undefined) {
    throw new Error(
      'The TestDataContext hook must be used within a TestDataProvider.Provider'
    );
  }
  return context;
}

export { TestDataProvider, useTestDataContext };

const filterGrowthPredictionData = tests => {
  const growIds = [
    Test.BIO_AGE, // '5737716d-7eb3-4724-85d2-7baa4652XXXX'
    Test.CHRONO_VS_BIO_AGE, // 491e8645-9273-4ef4-be2d-5d09363ac088
    Test.ADULT_HEIGHT_PREDICTION_CI, // 'f7529cf9-ba05-4570-bedd-6213ee3b6ec8'
    Test.GROWTH_PHASE, // '197bbf37-7db5-40c4-9675-0dec23339b8e'
    Test.BIOLOGICAL_AGE_M, // 'c4141f44-42ad-4620-bbd4-7d4f257f8d1b'
    Test.PERCENT_ADULT_HEIGHT_PREDICTION, // 'face72bd-d260-44a3-b55c-3bae9e3d18dc'
    Test.APHV, // '496d355a-4667-4253-8701-6a26bc7a3f59'
    Test.YEARS_FROM_APHV, // '7497420b-4a4f-4958-971e-f68aa41cbbe9'
    Test.ADULT_HEIGHT_PREDICTION_M, // '05c16b3e-0f75-4f17-a837-0d6163b0fd22'
    Test.ADULT_HEIGHT_PREDICTION_M_V2, // 'e4e9be42-7890-4188-8876-9f0c638fdcac'
    Test.ADULT_HEIGHT_PREDICTION_K_R, // '5737716d-7eb3-4724-85d2-7baa4652ae93'
    Test.DEVELOPMENT_Z_SCORE, // '105b2d29-1469-4d27-bdc0-9d9b6c44b915'
    Test.MATURATION_TIMING, // '0c58cad6-8a6f-402f-b30e-b00e06871f14'
    Test.DEVELOPMENT_M, // '9a7ec084-cc6b-45fd-9140-8a30169c1de1'
    Test.SKELETAL_AGE_SB,
    Test.SONIC_BONE_SB, // 'ab6f3f7c-1d00-49c8-b10d-cec81602bf7c'
    Test.AGE_AT_TEST_DATE,
    Test.PERCENT_ADULT_HEIGHT_PREDICTION_SB,
    Test.AGE_AT_TEST_DATE_VS_SKELETAL_AGE
  ];

  return (
    Array.isArray(tests) &&
    tests.map(test => {
      if (growIds.indexOf(test.id) !== -1) {
        test.category = 'B_growthprediction';
      }
      return test;
    })
  );
};

const unNullifyData = data => {
  const newData = structuredClone({ ...data });
  Object.keys(newData).forEach(key => {
    Object.keys(newData[key]).forEach(laterality => {
      newData[key][laterality].map(value => {
        // eslint-disable-next-line default-case
        switch (typeof value) {
          case 'string':
            if (value === null || value === '') {
              return '';
            }
            break;
          case 'number':
            if (isNaN(value) || value === null) {
              return '';
            }
            break;
          case 'object':
            if (value === null) {
              return '';
            }
            break;
          default:
            return value;
        }
      });
    });
  });

  return newData;
};
