import { useEffect } from 'react';

import Loader from 'components/loader/Loader';
import VirtualizedEntityList from 'containers/pages/persons/VirtualizedEntityList';
import GroupsBreadCrumbs from 'containers/partials/misc/GroupsBreadCrumbs';
import usePersonStatus from 'hooks/utils/usePersonStatus';
import useFetchAndFilterEntities from 'hooks/queries/useFetchAndFilterEntities';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import { useParams } from 'react-router-dom';

const PersonsGroupsTab = ({
  activeId,
  onClick,
  onBreadcrumbClicked,
  onModal = false,
  extraClassNamesForList = {},
  showOnlyGroups = false,
  checkableGroups = false,
  showPersonStatus = true,
  extraElement
}) => {
  const { entityId, groupId } = useParams();
  const { loading, fetchEntities } = useFetchAndFilterEntities({
    entityId
  });

  const group = useEntityContext(s => s.group);
  const groups = useEntityContext(s => s.groups);
  const persons = useEntityContext(s => s.persons);
  const resetFilter = useEntityContext(s => s.resetFilter);
  const clearSelected = useEntityContext(s => s.clearSelected);

  const { personStatusHandler } = usePersonStatus(entityId, groupId);

  useEffect(() => {
    return () => {
      resetFilter();
      clearSelected();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resetFilter();
    fetchEntities({ entityId: onModal ? entityId : groupId || entityId });
  }, [entityId, groupId]);

  const onGroupClickedHandler = async item => {
    await fetchEntities({ entityId: item.id });
  };
  const onBreadcrumbClickedHandler = async id => {
    if (onBreadcrumbClicked) {
      clearSelected();
      onBreadcrumbClicked(id || entityId);
    } else {
      await fetchEntities({ entityId: id });
    }
  };

  if (loading || !group?.id) return <Loader />;

  return (
    <>
      {group.id !== entityId && (
        <GroupsBreadCrumbs
          entityId={entityId}
          groupId={group.id}
          onClick={id => onBreadcrumbClickedHandler(id)}
        />
      )}

      <VirtualizedEntityList
        extraElement={!!extraElement && extraElement}
        id={'persons'}
        entityId={entityId}
        onModal={onModal}
        activeId={activeId}
        items={[...groups, ...persons]}
        checkable
        showPersonStatus={showPersonStatus}
        showOnlyGroups={showOnlyGroups}
        checkableGroups={checkableGroups}
        onClick={(item, type) => {
          if (type === 'group') {
            onGroupClickedHandler(item);
          }
          clearSelected();
          onClick && onClick(item, type);
        }}
        onClickPersonStatus={personStatusHandler}
        extraClassNames={extraClassNamesForList}
      />
    </>
  );
};

export default PersonsGroupsTab;
