import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useNotificationQueue } from 'components/notification';
import messages from 'messages';
import {
  MUTATION_ADD_GROUP_ENTITY,
  MUTATION_UPDATE_ENTITY
} from 'services/aws/entity-query';
import { QUERY_GET_GROUP_THREE_BY_ORGANISATION_ID } from 'services/aws/client-query';
import Loader from 'components/loader/Loader';
import useFetchAndFilterEntities from 'hooks/queries/useFetchAndFilterEntities';

function GroupForm({
  entityId,
  children,
  extraClassNames,
  parentGroup,
  group,
  mode,
  onAdded,
  onComplete
}) {
  const notification = useNotificationQueue();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const { fetchEntities } = useFetchAndFilterEntities({ entityId });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(messages.sessionNameRequired)
    ),
    grade: Yup.number()
      .min(1, intl.formatMessage(messages.minimumValidation, { value: 1 }))
      .max(12, intl.formatMessage(messages.maximumValidation, { value: 12 }))
      .typeError(intl.formatMessage(messages.gradeNotANumber))
  });

  const [addGroup] = useMutation(MUTATION_ADD_GROUP_ENTITY);
  const [editGroup] = useMutation(MUTATION_UPDATE_ENTITY);

  const onSubmitHandler = async (groupData, { resetForm }) => {
    setIsLoading(true);
    const meta = {};
    if (groupData.grade) {
      meta.grade = groupData.grade;
    }
    if (groupData.year) {
      meta.year = groupData.year;
    }

    const group = {
      name: groupData.name,
      meta: JSON.stringify(meta)
    };

    if (mode === 'edit') {
      //edit
      group.id = groupData.id;
      const { data } = await editGroup({
        variables: { ...group },
        refetchQueries: [
          {
            query: QUERY_GET_GROUP_THREE_BY_ORGANISATION_ID,
            variables: { entityId }
          }
        ]
      });

      if (data?.editEntity) {
        await fetchEntities({ entityId: data.editEntity.id });
        notification.add(data.editEntity.id, {
          message: intl.formatMessage(messages.messageGroupUpdated)
        });
        setIsLoading(false);
        onComplete && onComplete(data.editEntity.id);
      }
    } else {
      // add
      group.parentId = parentGroup.id;
      const { data } = await addGroup({
        variables: {
          ...group
        }
      });
      if (data?.createEntity) {
        resetForm();
        notification.add(data.createEntity.id, {
          message: intl.formatMessage(messages.messageGroupSaved)
        });

        setIsLoading(false);
        onAdded && onAdded(data.createEntity.id);
      }
    }
  };

  return (
    <Formik
      initialValues={group}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={false}
      onSubmit={onSubmitHandler}
    >
      {props => {
        return (
          <Form className={classNames(extraClassNames)} noValidate>
            {isLoading && <Loader />}
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
}

export default GroupForm;
