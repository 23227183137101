import './Icon.scss';
import classNames from 'classnames';
import svgSprite from 'assets/images/sprites.svg';

const Icon = props => {
  const {
    id,
    fillColor,
    bgColor,
    strokeColor,
    inheritColor,
    extraClassNames,
    tinyIcon,
    smallIcon,
    smallMediumIcon,
    mediumIcon,
    hugeIcon,
    xHugeIcon,
    rotateCW90,
    mirrored,
    style,
    title
  } = props;

  const svgPath = `${svgSprite}#${id}`;

  return (
    <svg
      className={classNames('c-icon', extraClassNames, {
        ['c-icon--fill-' + fillColor]: fillColor,
        ['c-icon--bg-' + bgColor]: bgColor,
        ['c-icon--stroke-' + strokeColor]: strokeColor,
        ['c-icon--inherit-' + inheritColor]: inheritColor,
        'c-icon--tiny': tinyIcon,
        'c-icon--small': smallIcon,
        'c-icon--small-medium': smallMediumIcon,
        'c-icon--medium': mediumIcon,
        'c-icon--huge': hugeIcon,
        'c-icon--x-huge': xHugeIcon,
        'c-icon--rotate-cw-90': rotateCW90,
        'c-icon--mirrored': mirrored
      })}
      style={style}
    >
      {title && <title>{title}</title>}
      <use href={svgPath} />
    </svg>
  );
};

export default Icon;
