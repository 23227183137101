import React, { Component, Fragment } from 'react';
import { Mutation, Query } from '@apollo/client/react/components';
import { inject } from 'mobx-react';
import { injectIntl } from 'react-intl';
import {
  MUTATION_ADD_PERSON_ENTITY,
  MUTATE_UPDATE_USER_ROLES,
  QUERY_GET_USER_BY_ID,
  REMOVE_PERSON_FROM_ENTITY,
  removePersonEntityMethod
} from 'services/aws/user-query';
import { QUERY_GET_GROUP_THREE_BY_ORGANISATION_ID } from 'services/aws/client-query';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { Card, CardBody } from 'components/card';
import { ButtonsGroup, Button } from 'components/button';
import Icon from 'components/icon/Icon';
import { ListItem } from 'components/list/list-item';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { PersonEntityRoles, EntityTypes } from 'constants.js';
import Select, { Option } from 'components/input/Select';
import { MenuWrapper } from 'components/menu';
import {
  Accordion,
  AccordionButton,
  AccordionContent
} from 'components/accordion';
import messages from 'messages';
import Person from 'models/Person';
import Loader from 'components/loader/Loader';

class PermissionsList extends Component {
  state = {
    editableItemId: null,
    isAddPermission: null,
    currentSelectedRole: null,
    currentSelectedEntity: null
  };

  getRole = roles => {
    if (roles) {
      if (roles.find(r => r === PersonEntityRoles.ROLE_ADMIN)) {
        return PersonEntityRoles.ROLE_ADMIN;
      } else if (roles.find(r => r === PersonEntityRoles.ROLE_SUB_ADMIN)) {
        return PersonEntityRoles.ROLE_SUB_ADMIN;
      } else if (roles.find(r => r === PersonEntityRoles.ROLE_REPORTER)) {
        return PersonEntityRoles.ROLE_REPORTER;
      } /* else if (roles.find(r => r === PersonEntityRoles.ROLE_TESTER)) {
        return PersonEntityRoles.ROLE_TESTER;
      }*/
    }
    return PersonEntityRoles.ROLE_NONE;
  };

  getRoleCopy = roles => {
    const { intl } = this.props;
    if (!roles) {
      return '';
    }
    if (roles.find(r => r === PersonEntityRoles.ROLE_ADMIN)) {
      return intl.formatMessage(messages.optionUserRoleAdmin);
    } else if (roles.find(r => r === PersonEntityRoles.ROLE_SUB_ADMIN)) {
      return intl.formatMessage(messages.optionUserRoleSubAdmin);
    } else if (roles.find(r => r === PersonEntityRoles.ROLE_REPORTER)) {
      return intl.formatMessage(messages.optionUserRoleReporter);
      /*} else if (roles.find(r => r === PersonEntityRoles.ROLE_TESTER)) {
      return intl.formatMessage(messages.optionUserRoleTester);*/
    } else {
      return intl.formatMessage(messages.optionUserRoleNone);
    }
  };

  getRolesArray = role => {
    switch (role) {
      case PersonEntityRoles.ROLE_ADMIN:
        return [
          PersonEntityRoles.ROLE_ADMIN,
          PersonEntityRoles.ROLE_SUB_ADMIN,
          PersonEntityRoles.ROLE_TESTER
        ];
      case PersonEntityRoles.ROLE_SUB_ADMIN:
        return [
          PersonEntityRoles.ROLE_SUB_ADMIN,
          PersonEntityRoles.ROLE_TESTER
        ];
      case PersonEntityRoles.ROLE_REPORTER:
        return [PersonEntityRoles.ROLE_REPORTER];
      /*case PersonEntityRoles.ROLE_TESTER:
        return [PersonEntityRoles.ROLE_TESTER];*/
      default:
        return [];
    }
  };

  addPermission = async (mutation, groupEntity, role) => {
    const { user, authStore } = this.props;
    await mutation({
      variables: {
        entityId: groupEntity.id,
        topLevelEntityId: authStore.user.rootEntityId,
        roles: this.getRolesArray(role),
        person: {
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          uid: ''
        }
      }
    });
    this.setState({ editableItemId: null, isAddPermission: false });

    if (authStore.user.id === user.id) {
      authStore.renewRoles();
    }
  };

  updatePersonEntityRole = async (mutation, personEntityId, role) => {
    const { user, authStore } = this.props;
    await mutation({
      variables: {
        personEntityId,
        roles: this.getRolesArray(role)
      }
    });
    this.setState({ editableItemId: null, isAddPermission: false });

    if (authStore.user.id === user.id) {
      authStore.renewRoles();
    }
  };

  removePersonEntity = async (mutation, personId, entityId) => {
    const { user, authStore } = this.props;
    await mutation({
      variables: {
        personId,
        entityId
      }
    });
    this.setState({ editableItemId: null, isAddPermission: false });

    if (authStore.user.id === user.id) {
      authStore.renewRoles();
    }
  };

  getDefaultSelectedEntity = (three, currentEntities) => {
    const orgExists = currentEntities.find(ce => ce.id === three.id);
    if (!orgExists) {
      return { id: three.id, name: three.name };
    }
    let defaultEntity = null;
    three.subEntities.some(e => {
      if (e.type !== EntityTypes.ORGANISATION) {
        const exists = currentEntities.find(ce => ce.id === e.id);
        if (!exists) {
          defaultEntity = { id: e.id, name: e.name };
          return true;
        }
      }
      return false;
    });
    return defaultEntity;
  };

  render() {
    const {
      intl,
      authStore,
      authStore: { entity },
      personEntities,
      user
    } = this.props;

    const {
      editableItemId,
      isAddPermission,
      currentSelectedRole,
      currentSelectedEntity
    } = this.state;

    const filteredEntities = personEntities.filter(
      e => e.entity.id !== entity.id
    );
    const currentOrgEntity = personEntities.find(
      e => e.entity.id === entity.id
    );
    const sortedEntities = [currentOrgEntity, ...filteredEntities];
    const currentRoleEntities = personEntities.map(e => ({
      id: e.entity.id,
      roleCopy: this.getRoleCopy(e.roles)
    }));

    return (
      <Query
        query={QUERY_GET_GROUP_THREE_BY_ORGANISATION_ID}
        variables={{ entityId: entity.id }}
        fetchPolicy={'cache-and-network'}
      >
        {result => {
          const { loading, error, data } = result;
          if (loading) return <Loader />;
          if (error) return <RetryPanel />;
          const entitiesTree = JSON.parse(data.getEntityTree).tree;
          entitiesTree.type = EntityTypes.GROUP;
          return (
            <List permissionsList>
              <ListBody>
                <Mutation
                  mutation={REMOVE_PERSON_FROM_ENTITY}
                  refetchQueries={[
                    {
                      query: QUERY_GET_USER_BY_ID,
                      variables: { id: user.id }
                    }
                  ]}
                >
                  {removePersonFromEntity => {
                    return (
                      <Mutation
                        mutation={MUTATE_UPDATE_USER_ROLES}
                        refetchQueries={[
                          {
                            query: QUERY_GET_USER_BY_ID,
                            variables: { id: user.id }
                          }
                        ]}
                      >
                        {updatePersonEntityRoles => {
                          return (
                            <Mutation
                              mutation={MUTATION_ADD_PERSON_ENTITY}
                              refetchQueries={[
                                {
                                  query: QUERY_GET_USER_BY_ID,
                                  variables: { id: user.id }
                                }
                              ]}
                            >
                              {addPersonToEntity => {
                                return (
                                  <Fragment>
                                    {sortedEntities.map(personEntity => {
                                      if (!personEntity) return null;
                                      const edit =
                                        editableItemId === personEntity?.id;
                                      const initRole = this.getRole(
                                        personEntity.roles
                                      );
                                      const initEntityId =
                                        personEntity.entity.id;
                                      if (
                                        EntityTypes.ORGANISATION ===
                                          personEntity.entity.type &&
                                        personEntity.entity.id !== entity.id
                                      )
                                        return null;

                                      if (
                                        personEntity.entity.id !== entity.id &&
                                        !Person.hasSubEntityInTree(
                                          entitiesTree,
                                          personEntity.entity.id
                                        )
                                      ) {
                                        return null;
                                      }
                                      return (
                                        <ListItem
                                          key={personEntity.id}
                                          cardEditableOpen={edit}
                                          cardEditable={true}
                                        >
                                          <Card secondary>
                                            <CardBody secondary>
                                              <span className="c-card-body__item">{`${
                                                user.firstname
                                              } ${
                                                user.lastname
                                              } ${intl.formatMessage(
                                                messages.labelUserPermissionIs
                                              )}`}</span>
                                              <RoleSelect
                                                extraClassNames="c-card-body__item"
                                                edit={edit}
                                                role={
                                                  edit
                                                    ? currentSelectedRole
                                                    : initRole
                                                }
                                                onChange={r =>
                                                  this.setState({
                                                    currentSelectedRole: r
                                                  })
                                                }
                                                noTester={
                                                  currentSelectedEntity &&
                                                  currentSelectedEntity.type ===
                                                    EntityTypes.GROUP
                                                }
                                              />
                                              <span className="c-card-body__item">
                                                {intl.formatMessage(
                                                  messages.labelUserPermissionFor
                                                )}
                                              </span>
                                              <EntitySelect
                                                extraClassNames="c-card-body__item"
                                                disabledEntities={
                                                  currentRoleEntities
                                                }
                                                entitiesTree={entitiesTree}
                                                edit={
                                                  edit &&
                                                  personEntity.entity.id !==
                                                    entity.id
                                                }
                                                entity={
                                                  edit
                                                    ? currentSelectedEntity
                                                    : personEntity.entity
                                                }
                                                onChange={v =>
                                                  this.setState({
                                                    currentSelectedEntity: v
                                                  })
                                                }
                                              />
                                            </CardBody>
                                          </Card>
                                          {authStore.user.canEditUserRoles() && (
                                            <EditButtonGroup
                                              edit={edit}
                                              canDelete={
                                                entity.id !==
                                                personEntity.entity.id
                                              }
                                              disabled={
                                                !currentSelectedEntity ||
                                                !currentSelectedRole
                                              }
                                              onSubmit={async () => {
                                                if (
                                                  initEntityId ===
                                                  currentSelectedEntity.id
                                                ) {
                                                  if (
                                                    initRole ===
                                                    currentSelectedRole
                                                  ) {
                                                    this.setState({
                                                      editableItemId: null
                                                    });
                                                  } else {
                                                    this.updatePersonEntityRole(
                                                      updatePersonEntityRoles,
                                                      personEntity.id,
                                                      currentSelectedRole
                                                    );
                                                  }
                                                } else {
                                                  await removePersonEntityMethod(
                                                    {
                                                      personId: user.id,
                                                      entityId: initEntityId
                                                    }
                                                  );
                                                  this.addPermission(
                                                    addPersonToEntity,
                                                    currentSelectedEntity,
                                                    currentSelectedRole
                                                  );
                                                }
                                              }}
                                              onEdit={() =>
                                                this.setState({
                                                  isAddPermission: false,
                                                  editableItemId:
                                                    personEntity.id,
                                                  currentSelectedEntity:
                                                    personEntity.entity,
                                                  currentSelectedRole:
                                                    this.getRole(
                                                      personEntity.roles
                                                    )
                                                })
                                              }
                                              onCancel={() =>
                                                this.setState({
                                                  editableItemId: null
                                                })
                                              }
                                              onDelete={() => {
                                                this.removePersonEntity(
                                                  removePersonFromEntity,
                                                  user.id,
                                                  personEntity.entity.id
                                                );
                                              }}
                                            />
                                          )}
                                        </ListItem>
                                      );
                                    })}
                                    {isAddPermission && (
                                      <ListItem
                                        cardEditableOpen={true}
                                        cardEditable={true}
                                      >
                                        <Card secondary>
                                          <CardBody secondary>
                                            <span className="c-card-body__item">{`${
                                              user.firstname
                                            } ${user.lastname}

                                              ${intl.formatMessage(
                                                messages.labelUserPermissionIs
                                              )}`}</span>
                                            <RoleSelect
                                              extraClassNames="c-card-body__item"
                                              noTester
                                              edit={true}
                                              role={currentSelectedRole}
                                              onChange={v =>
                                                this.setState({
                                                  currentSelectedRole: v
                                                })
                                              }
                                            />
                                            <span className="c-card-body__item">
                                              {intl.formatMessage(
                                                messages.labelUserPermissionFor
                                              )}
                                            </span>
                                            <EntitySelect
                                              extraClassNames="c-card-body__item"
                                              disabledEntities={
                                                currentRoleEntities
                                              }
                                              entitiesTree={entitiesTree}
                                              edit={true}
                                              entity={currentSelectedEntity}
                                              onChange={v =>
                                                this.setState({
                                                  currentSelectedEntity: v
                                                })
                                              }
                                            />
                                          </CardBody>
                                        </Card>
                                        <EditButtonGroup
                                          edit={true}
                                          disabled={
                                            !currentSelectedEntity ||
                                            !currentSelectedRole
                                          }
                                          onSubmit={() =>
                                            this.addPermission(
                                              addPersonToEntity,
                                              currentSelectedEntity,
                                              currentSelectedRole
                                            )
                                          }
                                          onCancel={() =>
                                            this.setState({
                                              isAddPermission: false
                                            })
                                          }
                                        />
                                      </ListItem>
                                    )}
                                  </Fragment>
                                );
                              }}
                            </Mutation>
                          );
                        }}
                      </Mutation>
                    );
                  }}
                </Mutation>
              </ListBody>
            </List>
          );
        }}
      </Query>
    );
  }
}

const RoleSelect = injectIntl(
  ({ intl, edit, role, onChange, noTester, extraClassNames }) => (
    <Select
      extraClassNames={extraClassNames}
      readOnly={!edit}
      value={role}
      onChange={({ value }) => onChange(value)}
    >
      <Option
        value={PersonEntityRoles.ROLE_ADMIN}
        label={intl.formatMessage(messages.optionUserRoleAdmin)}
      />
      <Option
        value={PersonEntityRoles.ROLE_SUB_ADMIN}
        label={intl.formatMessage(messages.optionUserRoleSubAdmin)}
      />
      <Option
        value={PersonEntityRoles.ROLE_REPORTER}
        label={intl.formatMessage(messages.optionUserRoleReporter)}
      />
      {/*{!noTester && (
        <Option
          value={PersonEntityRoles.ROLE_TESTER}
          label={intl.formatMessage(messages.optionUserRoleTester)}
        />
      )}*/}
      <Option
        value={PersonEntityRoles.ROLE_NONE}
        label={intl.formatMessage(messages.optionUserRoleNone)}
      />
    </Select>
  )
);

const getAccordionLayer = (
  { id, name, type, subEntities },
  onChange,
  topCloseHandler,
  disabledEntities
) => {
  if (type === EntityTypes.ORGANISATION) {
    return null;
  }
  return (
    <Accordion>
      {closeHandler => {
        const rootActiveEntity = disabledEntities.find(e => e.id === id);
        return (
          <Fragment>
            <AccordionButton
              id={id}
              name={name}
              disabled={rootActiveEntity}
              onClick={() => {
                onChange({ id, name, type });
                topCloseHandler ? topCloseHandler() : closeHandler();
              }}
              selectableButton
            >
              <span>{name}</span>
              <span>{rootActiveEntity ? rootActiveEntity.roleCopy : ''}</span>
            </AccordionButton>
            <AccordionContent>
              {subEntities &&
                subEntities.length > 0 &&
                subEntities.map(entity => {
                  const childActiveEntity = disabledEntities.find(
                    e => e.id === entity.id
                  );
                  if (entity.type === EntityTypes.ORGANISATION) {
                    return null;
                  }
                  return entity.subEntities && entity.subEntities.length > 0 ? (
                    <Fragment key={entity.id}>
                      {getAccordionLayer(
                        entity,
                        onChange,
                        topCloseHandler ? topCloseHandler : closeHandler,
                        disabledEntities
                      )}
                    </Fragment>
                  ) : (
                    <Button
                      key={entity.id}
                      menu
                      disabled={childActiveEntity}
                      onClick={() => {
                        onChange(entity);
                        topCloseHandler ? topCloseHandler() : closeHandler();
                      }}
                    >
                      <Icon id="folder" />
                      <span>{entity.name}</span>
                      <span>
                        {childActiveEntity ? childActiveEntity.roleCopy : ''}
                      </span>
                    </Button>
                  );
                })}
            </AccordionContent>
          </Fragment>
        );
      }}
    </Accordion>
  );
};

const EntitySelect = injectIntl(
  ({ edit, entity, onChange, disabledEntities = [], entitiesTree }) => {
    const filteredDisabledEntities = disabledEntities.filter(
      e => !entity || e.id !== entity.id
    );
    return !edit ? (
      <Select
        readOnly
        value={entity.id}
        onChange={({ value, label }) => onChange({ id: value, name: label })}
      >
        <Option value={entity.id} label={entity.name} />
      </Select>
    ) : (
      <MenuWrapper
        trigger={<Button select>{entity ? entity.name : '-'}</Button>}
      >
        {getAccordionLayer(
          entitiesTree,
          onChange,
          null,
          filteredDisabledEntities
        )}
      </MenuWrapper>
    );
  }
);

const EditButtonGroup = inject('uiState')(
  injectIntl(
    ({
      intl,
      uiState,
      edit,
      onSubmit,
      onCancel,
      onEdit,
      onDelete,
      disabled,
      canDelete
    }) => (
      <Fragment>
        {edit ? (
          <ButtonsGroup>
            <Button tiny onClick={onSubmit} disabled={disabled}>
              {intl.formatMessage(messages.buttonUserSavePermission)}
            </Button>
            <Button tiny secondary onClick={onCancel}>
              {intl.formatMessage(messages.buttonUserDiscardPermission)}
            </Button>
          </ButtonsGroup>
        ) : (
          <ButtonsGroup>
            <Button tiny onClick={onEdit}>
              <Icon id="edit" />
            </Button>

            {/*<Button
              tiny
              disabled={!canDelete}
              onClick={e => {
                e.preventDefault();
                uiState.showModal({
                  title: intl.formatMessage(
                    messages.titleUserConfirmRemovePermission
                  ),
                  message: messages.messageUserConfirmRemovePermission,
                  okHandler: () => onDelete && onDelete(),
                  type: MODAL_TYPES.ALERT
                });
              }}
            >
              <Icon id="delete" />
            </Button>*/}
          </ButtonsGroup>
        )}
      </Fragment>
    )
  )
);

export default injectIntl(inject('authStore')(PermissionsList));
