import {
  cloneElement,
  useCallback,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { useUIDSeed } from 'react-uid';
import VirtualListItem from 'containers/partials/list-items/VirtualListItem';
import PersonStatusPopper from 'containers/partials/misc/PersonStatusPopper';
import ListItemLabel from 'components/list/list-item/ListItemLabel';
import { PersonStatus } from 'constants.js';
import { useVirtualizer } from '@tanstack/react-virtual';
import MetaWrapper from 'containers/partials/list-items/MetaWrapper';
import Badge from 'components/badge/Badge';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import SelectAndFilterEntities from 'containers/pages/persons/SelectAndFilterEntities';

const VirtualizedEntityList = ({
  id,
  activeId,
  items = [],
  statusLabel,
  checkableGroups,
  checkable,
  enableSelectAll = true,
  showOnlyGroups,
  sortableListHeader,
  onClick,
  disabledItems = [],
  extraClassNames = {},
  onModal,
  showPersonStatus,
  onClickPersonStatus,
  withFixedFooter,
  emptyMessage,
  reCalculateHeight = false,
  variableHeight = false,
  children
}) => {
  const selected = useEntityContext(s => s.selectedPersons);
  const selectedGroups = useEntityContext(s => s.selectedGroups);
  const selectPersons = useEntityContext(s => s.selectPersons);
  const excludedPersons = useEntityContext(s => s.excludedPersons);
  const selectGroups = useEntityContext(s => s.selectGroups);
  const excludedGroups = useEntityContext(s => s.excludedGroups);
  const filter = useEntityContext(s => s.filter);

  const parentRef = useRef();
  const uidSeed = useUIDSeed();

  const [minHeight, setMinHeight] = useState(null);
  const [minHeightParent, setMinHeightParent] = useState(null);
  const [heightList, setHeightList] = useState(null);

  const rowVirtualizer = useVirtualizer({
    count: items?.length ?? 0,
    getScrollElement: () => parentRef.current,
    estimateSize: useCallback(() => 56, []),
    overscan: 5
  });

  const onSelectHandler = item => {
    if (
      item.constructor.modelName === 'Person' ||
      item.constructor.modelName === 'User'
    ) {
      if (excludedPersons.find(exI => exI.id === item.id)) return;

      selectPersons([item]);
    } else if (item.constructor.modelName === 'Group') {
      if (excludedGroups.includes(item.id)) return;

      selectGroups([item]);
    }
  };

  const checkAndSetHeight = theRef => {
    if (theRef?.current) {
      const roundedTop = theRef.current.getBoundingClientRect().top.toFixed(0);

      setMinHeight(`calc(100dvh - 32px - ${roundedTop}px)`);
      setMinHeightParent(`calc(100dvh - ${roundedTop}px)`);
    }
  };

  const reCalculate = (top = false) => {
    if (top !== false) {
      setHeightList(`calc(100dvh - ${top}px - 70px)`);
    } else {
      const roundedTop = parentRef?.current
        ?.getBoundingClientRect()
        .top.toFixed(0);

      const footerSpacing = document.querySelector(
        '.c-card__footer-bottom-fixed'
      )
        ? 70
        : 0;
      return `calc(100dvh - ${roundedTop}px - ${footerSpacing}px)`;
    }
  };

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      if (parentRef) {
        checkAndSetHeight(parentRef);
        variableHeight && reCalculate();
        clearInterval(interval);
      }
    }, 100);
  }, [parentRef, items]);

  if (!Array.isArray(items)) return null;

  // mark as checked when all persons are selected in the group
  const groupIsChecked = group => {
    return (
      selectedGroups.find(s => s.id === group.id) ||
      (!checkableGroups && //only when checkableGroups is false
        group.clients.length > 0 &&
        group.clients.every(client =>
          new Set([
            ...selected.map(s => s.id),
            ...excludedPersons.map(exI => exI.id)
          ]).has(client.id)
        ))
    );
  };

  // mark as indeterminate when some persons are selected in the group
  const isIndeterminateChecked = group => {
    return (
      group.clients.some(
        client =>
          selected.find(p => p.id === client.id) ||
          excludedPersons.find(exI => exI.id === client.id)
      ) &&
      !group.clients.every(client =>
        [
          ...selected.map(s => s.id),
          ...excludedPersons.map(exI => exI.id)
        ].includes(client.id)
      )
    );
  };

  // Mark as checked when the person is in the selected array or in the excludedItems array
  const personIsChecked = person => {
    return (
      selected.find(p => p.id === person.id) ||
      excludedPersons.find(exI => exI.id === person.id)
    );
  };

  const onSearch = () => {
    parentRef.current.scrollTop = 0;
  };

  if (items.length > 0) {
    return (
      <>
        <SelectAndFilterEntities
          id={id}
          onModal={onModal}
          enableSelectAll={enableSelectAll}
          onSearchCallback={onSearch}
        />
        {sortableListHeader && sortableListHeader}
        <List
          ref={parentRef}
          virtualizedList
          onModal={onModal}
          style={{
            maxHeight: `${minHeightParent}`,
            height: withFixedFooter ? 'calc(100% - 64px)' : '100%'
          }}
          extraClassNames={extraClassNames}
        >
          <ListBody
            style={{
              minHeight: !onModal && !reCalculateHeight && minHeight,
              height: reCalculateHeight
                ? reCalculate()
                : rowVirtualizer.getTotalSize(),
              position: 'relative',
              marginBottom: 0
            }}
          >
            {rowVirtualizer.getVirtualItems().map(virtualRow => {
              const item = items[virtualRow.index];
              if (!item) return null;
              item.index = virtualRow.index;

              const disabled = disabledItems.find(exI => exI.id === item.id);
              const excluded = excludedPersons.find(exP => exP.id === item.id);
              const excludedGroup = excludedGroups.includes(item.id);

              let listData;

              if (
                item.constructor.modelName === 'Person' ||
                item.constructor.modelName === 'User'
              ) {
                listData = {
                  titleLabel: 'fullName',
                  statusLabel:
                    statusLabel && typeof statusLabel === 'function'
                      ? statusLabel(item)
                      : statusLabel || item.namePrefix,
                  checkbox: checkable && !excluded && !!!filter.year,
                  onClick: () =>
                    !!!filter.year && onClick && onClick(item, 'person'),
                  checked: checkable && personIsChecked(item) && !excluded,
                  lastItem:
                    item.index === rowVirtualizer.getVirtualItems().length - 1,
                  meta:
                    (showPersonStatus && (
                      <MetaWrapper>
                        <PersonStatusIcon
                          item={item}
                          onClick={onClickPersonStatus}
                        />
                      </MetaWrapper>
                    )) ||
                    (excluded && (
                      <MetaWrapper>
                        <Badge variant="badge-dark">
                          <FormattedMessage {...messages.excludedEntityLabel} />{' '}
                        </Badge>
                      </MetaWrapper>
                    ))
                };
              } else if (item.constructor.modelName === 'Group') {
                listData = {
                  titleLabel: 'name',
                  checkbox: checkableGroups,
                  checked: checkableGroups && groupIsChecked(item),
                  disabled: !checkableGroups || excludedGroup,
                  indeterminate:
                    !checkableGroups && isIndeterminateChecked(item),
                  mediumIcon: false,
                  icon: 'group',
                  visible: groupIsChecked(item),
                  onClick: () =>
                    ((onClick &&
                      showOnlyGroups &&
                      item.subEntities.length > 0) ||
                      (onClick && !showOnlyGroups)) &&
                    onClick(item, 'group'),
                  meta: (excludedGroup && (
                    <MetaWrapper>
                      <Badge variant="badge-dark">
                        <FormattedMessage {...messages.excludedEntityLabel} />{' '}
                      </Badge>
                    </MetaWrapper>
                  )) || (
                    <MetaWrapper>
                      <p className="c-list__meta">
                        <FormattedMessage
                          {...messages.modalListItemSportersGroupsCount}
                          values={{
                            athletes:
                              (item.clients && item.clients.length) || 0,
                            groups: item.subEntities.length
                          }}
                        />
                      </p>
                    </MetaWrapper>
                  )
                };
              }
              if (showOnlyGroups && item.constructor.modelName === 'Person')
                return null;

              if (children) {
                const ChildrenItems = children(item);
                return cloneElement(ChildrenItems, {
                  uid: uidSeed(item.id),
                  key: virtualRow.index,
                  item: item,
                  virtualRow: virtualRow,
                  virtualRef: rowVirtualizer.measureElement,
                  onChange: e => onSelectHandler(item, e),
                  ...listData
                });
              }

              const personDisabled =
                !!filter.year &&
                (item.constructor.modelName === 'Person' ||
                  item.constructor.modelName === 'User');

              return (
                <VirtualListItem
                  uid={uidSeed(item.id)}
                  item={item}
                  key={virtualRow.index}
                  virtualRow={virtualRow}
                  active={item.id === activeId}
                  statusLabel={statusLabel}
                  clickable={
                    !excluded && item.filtered && !personDisabled
                    //&&
                    /*!(
                      item.constructor.modelName === 'Group' &&
                      filter.search.length > 0 &&
                      !onModal
                    )*/
                  }
                  outfaded={
                    excluded || !item.filtered || personDisabled
                    /*(item.constructor.modelName === 'Group' &&
                      filter.search.length > 0 &&
                      !onModal)*/
                  }
                  disabled={disabled}
                  filtered={item.filtered}
                  absolutePositionedListItem
                  onChange={e => onSelectHandler(item, e)}
                  /*filteringTerm={
                    onModal
                      ? filter
                      : item.constructor.modelName !== 'Group' && filter
                  }*/
                  filteringTerm={filter}
                  {...listData}
                />
              );
            })}
          </ListBody>
        </List>
      </>
    );
  }

  return (
    <Card centered>
      <CardBody empty>
        <Message emptyState={true} icon="cursor">
          <MessageText>
            <FormattedMessage
              {...(emptyMessage ? emptyMessage : messages.noAthletesOrGroups)}
            />
          </MessageText>
        </Message>
      </CardBody>
    </Card>
  );
};

export default VirtualizedEntityList;

const PersonStatusIcon = ({ item, onClick }) => {
  if (
    ![
      PersonStatus.EMAIL,
      PersonStatus.USER,
      PersonStatus.PENDING,
      PersonStatus.UNCONFIRMED
    ].includes(item.status)
  )
    return null;

  return (
    <ListItemLabel info middleColumn style={{ paddingRight: 24 }}>
      <PersonStatusPopper person={item} onClickStatus={onClick} />
    </ListItemLabel>
  );
};
