import React, { Fragment, useContext, useEffect, useState } from 'react';
import { StoreContext } from 'index';
import { observer } from 'mobx-react';
import { withRouter, Switch, Redirect, Route } from 'react-router-dom';

import {
  ROUTE_LOGIN,
  ROUTE_FORGOT,
  ROUTE_SIGNUP,
  ROUTE_SETTINGS,
  ROUTE_SPORTERS,
  ROUTE_SESSIONS,
  ROUTE_TESTS,
  ROUTE_NO_ROLES,
  ROUTE_SCANNERS,
  ROUTE_FORGOT_SUCCESS,
  ROUTE_GROWTH_PREDICTION,
  ROUTE_EXERCISES,
  ROUTE_PREVENTION,
  ROUTE_NO_ACCESS,
  ROUTE_USER_PROGRAMS,
  ROUTE_EXERCISES_TEMPLATES,
  ROUTE_EXERCISES_PROGRAMS,
  ROUTE_EXERCISES_COLLECTIONS,
  ROUTE_EXERCISES_TRAININGS,
  ROUTE_LESSONS,
  ROUTE_VIDEOS,
  ROUTE_HOME,
  ROUTE_RESULTS,
  ROUTE_TEST,
  ROUTE_USER_DOCUMENTS,
  ROUTE_TOTP,
  ROUTE_MAGIC_LOGIN,
  ROUTE_GROWTH
} from './RouteList';

// Route types
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

// ROOT auth pages
import Login from '../containers/pages/auth/Login';
import Signup from '../containers/pages/auth/Signup';
import ForgotLogin from '../containers/pages/auth/ForgotLogin';
import PasswordResetSuccess from '../containers/pages/auth/PasswordResetSuccess';

// ROOT pages
import Settings from '../containers/pages/settings';
import Sporters from '../containers/pages/persons';
import Sessions from 'containers/pages/sessions';
import Tests from '../containers/pages/tests';
import Scanners from '../containers/pages/scanners';
import { withErrorBoundary } from 'containers/partials/error-boundary/ErrorBoundary';
import NoRolesView from '../containers/partials/no-roles/NoRolesView';
import GrowthPrediction from '../containers/pages/growthprediction';
import Exercises from '../containers/pages/exercises';
import GSVExercises from '../containers/pages/exercises/GSVExercises';
import Prevention from '../containers/pages/prevention';
import NoAccess from 'containers/pages/auth/NoAccess';
import UserPrograms from 'containers/pages/person/programs';
import UserSessions from 'containers/pages/person/sessions';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import GSVCollections from 'containers/pages/exercises/GSVCollections';
import GSVTrainings from 'containers/pages/exercises/GSVTrainings';
import LessonsIndex from 'containers/pages/lessons';
import VideosIndex from 'containers/pages/videos';
import IFramePage from 'containers/pages/misc/IFramePage';
import Results from 'containers/pages/results';
import Test from '../containers/pages/test';
import UserDocuments from 'containers/pages/person/documents';
import TotpForm from 'containers/pages/auth/TotpForm';
import MagicLogin from 'containers/pages/auth/MagicLogin';
import GrowthTracker from 'containers/pages/growthtracker';

const AppRoute = ({ brand }) => {
  const {
    authStore: { user, isLoggedIn, isSigningUp },
    uiState
  } = useContext(StoreContext);
  const [privateReady, setPrivateReady] = useState(false);
  const [noRolesReady, setNoRolesReady] = useState(false);
  const [isGlobalTester, setIsGlobalTester] = useState(false);

  useEffect(() => {
    if (isLoggedIn && user && user.personReady && uiState.appIsInSync) {
      const globalTester = isLoggedIn && user.isGlobalTester;
      setIsGlobalTester(globalTester);

      if (!user.hasNoRoles() && !globalTester) {
        setPrivateReady(true);
      }
      if (user.hasNoRoles() && globalTester) {
        setNoRolesReady(true);
      }
    }
  }, [isLoggedIn, uiState.appIsInSync, user]);

  return (
    <Fragment>
      <Switch>
        <Route exact path={ROUTE_MAGIC_LOGIN}>
          <MagicLogin />
        </Route>
        <Route exact path={ROUTE_NO_ACCESS}>
          <NoAccess
            key={'no-access-route'}
            messages={"I'm sorry"}
            //   goToEntity={entityId}
          />
        </Route>
        <PublicRoute
          exact
          path={ROUTE_LOGIN}
          component={<Login brand={brand} />}
        />
        <PublicRoute exact path={ROUTE_FORGOT} component={<ForgotLogin />} />
        <PublicRoute
          exact
          path={ROUTE_FORGOT_SUCCESS}
          component={<PasswordResetSuccess />}
        />
        <PublicRoute exact path={ROUTE_TOTP} component={<TotpForm />} />
        <PublicRoute exact path={ROUTE_SIGNUP} component={<Signup />} />

        <PublicRoute path={ROUTE_TESTS} component={Tests} />

        {!isLoggedIn && !isSigningUp && <Redirect to={ROUTE_LOGIN} />}

        {noRolesReady && (
          <Switch>
            {!isGlobalTester &&
            user.routes.includes(ROUTE_SPORTERS) &&
            user.hasGroupsToShow ? (
              <PrivateRoute path={ROUTE_SPORTERS} component={Sporters} />
            ) : user.rolesReady ? (
              <PrivateRoute path={ROUTE_NO_ROLES} component={NoRolesView} />
            ) : null}
            <Redirect
              to={user.hasGroupsToShow ? ROUTE_SPORTERS : ROUTE_NO_ROLES}
            />
            {/*<PrivateRoute path={ROUTE_NO_ROLES} component={NoRolesView} />
              <Redirect to={ROUTE_NO_ROLES} />*/}
          </Switch>
        )}

        {privateReady && <PrivateRoutes user={user} />}
      </Switch>
    </Fragment>
  );
};

const PrivateRoutes = ({ user }) => {
  const { uiState } = useContext(StoreContext);
  const ability = useAbility(AbilityContext);

  if (user.isGSVOrg) {
    return (
      <Switch>
        <PrivateRoute path={ROUTE_EXERCISES} component={GSVExercises} />
        <PrivateRoute
          path={ROUTE_EXERCISES_COLLECTIONS}
          component={GSVCollections}
        />
        <PrivateRoute
          path={ROUTE_EXERCISES_TRAININGS}
          component={GSVTrainings}
        />
        <PrivateRoute path={ROUTE_SETTINGS} component={Settings} />
        <PrivateRoute path={ROUTE_SPORTERS} component={Sporters} />
        <Redirect to={uiState.homeRoute} />
      </Switch>
    );
  }

  if (ability.can('manage', 'Vitale')) {
    return (
      <Switch>
        <PrivateRoute
          exact
          path={ROUTE_HOME}
          component={IFramePage}
          src={user.homepageUrl}
        />
        <PrivateRoute path={ROUTE_LESSONS} component={LessonsIndex} />
        <PrivateRoute path={ROUTE_VIDEOS} component={VideosIndex} />
        <PrivateRoute path={ROUTE_SPORTERS} component={Sporters} />
        <PrivateRoute path={ROUTE_SETTINGS} component={Settings} />
        <Redirect to={uiState.homeRoute} />
      </Switch>
    );
  }

  return (
    <>
      {user.isAthlete() && (
        <Switch>
          <PrivateRoute path={ROUTE_SESSIONS} component={UserSessions} />
          <PrivateRoute path={ROUTE_USER_PROGRAMS} component={UserPrograms} />
          <PrivateRoute path={ROUTE_USER_DOCUMENTS} component={UserDocuments} />
          <Redirect to={uiState.redirectRoute ?? uiState.homeRoute} />
        </Switch>
      )}

      {user.hasAdminRole() && (
        <Switch>
          {user.homepageUrl && (
            <PrivateRoute
              exact
              path={ROUTE_HOME}
              component={IFramePage}
              src={user.homepageUrl}
            />
          )}
          {/*<PrivateRoute path={ROUTE_PACKAGES} component={Packages} />*/}

          <PrivateRoute path={ROUTE_SPORTERS} component={Sporters} />

          <PrivateRoute path={ROUTE_SESSIONS} component={Sessions} />

          <PrivateRoute path={ROUTE_RESULTS} component={Results} />
          {ability.can('read', 'Scanners') && (
            <PrivateRoute path={ROUTE_SCANNERS} component={Scanners} />
          )}

          <PrivateRoute
            path={ROUTE_GROWTH_PREDICTION}
            component={GrowthPrediction}
          />

          <PrivateRoute path={ROUTE_GROWTH} component={GrowthTracker} />

          <PrivateRoute path={ROUTE_PREVENTION} component={Prevention} />

          <PrivateRoute path={ROUTE_EXERCISES} component={Exercises} />

          <PrivateRoute path={ROUTE_EXERCISES_PROGRAMS} component={Exercises} />

          <PrivateRoute
            path={ROUTE_EXERCISES_TEMPLATES}
            component={Exercises}
          />

          <PrivateRoute path={ROUTE_TEST} component={Test} />

          <PrivateRoute path={ROUTE_SETTINGS} component={Settings} />

          <Redirect to={uiState.homeRoute} />
        </Switch>
      )}
    </>
  );
};
export default withRouter(withErrorBoundary(observer(AppRoute)));
