import { useContext, useEffect, useState } from 'react';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import { StoreContext } from 'index';
import ConfirmButton from 'containers/partials/buttons/ConfirmButton';
import { archiveGroupMutation } from 'services/aws/entity-query';
import { useNotificationQueue } from 'components/notification';
import { useIntl } from 'react-intl';
import useFetchAndFilterEntities from 'hooks/queries/useFetchAndFilterEntities';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

const RemoveGroupsButton = ({ groups, entityId, children, ...props }) => {
  const {
    authStore: { user },
    uiState
  } = useContext(StoreContext);
  const intl = useIntl();
  const [groupNames, setGroupNames] = useState('');
  const notification = useNotificationQueue();
  const { fetchEntities } = useFetchAndFilterEntities({ entityId });
  const clearSelected = useEntityContext(s => s.clearSelected);

  useEffect(() => {
    if (Array.isArray(groups) && groups.length > 0) {
      const names = groups.map(group => group.name).join(', ');
      setGroupNames(names);
    }
  }, [groups]);

  if (!user.canRemoveGroups(entityId)) return null;

  const onConfirmRemoveGroups = async () => {
    uiState.increasePendingRequest();
    if (Array.isArray(groups) && groups.length > 0) {
      for (const group of groups) {
        await archiveGroupMutation({
          variables: {
            id: group.id
          }
        });
      }
      await fetchEntities({ entityId });
      clearSelected();
      uiState.decreasePendingRequest();

      notification.add(`groupsArchive`, {
        message: intl.formatMessage(messages.modalRemoveGroupsConfirmed)
      });
    }
  };

  return (
    <ConfirmButton
      {...props}
      confirmTitle={messages.modalConfirmRemoveGroupsTitle}
      confirmMessage={messages.modalConfirmRemoveGroupsMessage}
      messageValues={{
        count: groups.length,
        groups: groupNames,
        b: chunks => <b>{chunks}</b>,
        br: () => <br />
      }}
      onOk={onConfirmRemoveGroups}
    >
      <Icon id="delete" />
      {children}
    </ConfirmButton>
  );
};

export default RemoveGroupsButton;
