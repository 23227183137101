import {
  PersonEntityRoles,
  defaultConfig,
  gsvConfig,
  OrganisationTypes,
  hylyghtConfig,
  vitaleConfig,
  routeAbilities
} from 'constants.js';
import { defaultMTSMetaConfig } from 'default-vars';
import {
  ROUTE_EXERCISES_PROGRAMS,
  ROUTE_HOME,
  ROUTE_RESULTS,
  ROUTE_SESSIONS,
  ROUTE_SPORTERS,
  ROUTE_USER_DOCUMENTS
} from 'routes/RouteList';

export default class Person {
  id = null;
  uid = '';
  hmac = '';
  parentId = null;
  lastname = '';
  firstname = '';
  birthdate = '';
  gender = '';
  dominantHand = '';
  email = '';
  athleteEmail = '';
  rootEntityId = null;
  entities = [];
  entityId = null;
  entityRoles = [];
  entityTree = null;
  baseLanguage = 'en';
  notes = '';
  meta;
  account_meta;
  integrations = {};
  weight = '';
  length = '';
  vegetarianDate = '';
  betaAlanineDate = '';
  creatine = false;
  creatineDate = '';
  taurine = false;
  taurineDate = '';

  personReady = false;
  rolesReady = false;

  status;

  filtered = true;

  constructor(
    {
      id,
      uid = '',
      hmac = '',
      parentId,
      lastname = '',
      firstname = '',
      gender = '',
      dominantHand = '',
      email = '',
      athleteEmail = '',
      entities = [],
      birthdate = '',
      notes = '',
      baseLanguage,
      meta,
      status,
      account_meta,
      integrations,
      weight,
      length,
      vegetarianDate,
      betaAlanineDate,
      creatine = false,
      creatineDate,
      taurine = false,
      taurineDate,
      finished = 0,
      filtered = true
    },
    entityId
  ) {
    this.id = id;
    this.uid = uid || '';
    this.hmac = hmac || '';
    this.parentId = parentId;
    this.lastname = lastname || '';
    this.firstname = firstname || '';
    this.birthdate = birthdate ? new Date(birthdate) : '';
    this.gender = gender || '';
    this.dominantHand = dominantHand || '';
    try {
      this.meta = meta ? JSON.parse(meta) : {};
    } catch (e) {
      this.meta = meta;
    }
    try {
      this.account_meta = account_meta ? JSON.parse(account_meta) : {};
    } catch (e) {
      this.account_meta = account_meta;
    }
    try {
      this.integrations = integrations ? JSON.parse(integrations) : {};
    } catch (e) {
      this.integrations = integrations;
    }
    this.status = status;
    this.email = email?.trim() || ''; // email is the login email
    this.athleteEmail = this.meta?.email
      ? (this.meta?.email).toString().trim()
      : ''; // only to contact the athlete, no login
    this.notes = notes || '';
    this.baseLanguage = baseLanguage;
    this.entityId = entityId;
    this.weight = weight;
    this.length = length;
    this.vegetarianDate = vegetarianDate;
    this.betaAlanineDate = betaAlanineDate;
    this.creatine = creatine;
    this.creatineDate = creatineDate;
    this.taurine = taurine;
    this.taurineDate = taurineDate;
    this.finished = finished;

    this.filtered = filtered;

    this.personConfig = defaultConfig;
  }

  hasInValidLogin() {
    return this.email && this.account_meta.status === 'FORCE_CHANGE_PASSWORD';
  }
  hasEmailNoLogin() {
    return this.athleteEmail && !this.email;
  }
  hasEmail() {
    return this.athleteEmail || this.email;
  }

  hasRole(role) {
    return this.entityRoles.includes(role);
  }

  getEntityName(entity) {
    return entity.name;
  }

  get testSets() {
    return []; // this.activeEntity.entity.testSets;
  }

  setPersonReady(value) {
    this.personReady = value;
  }

  setEntityId(entityId) {
    this.rootEntityId = entityId;
  }

  getDefaultRoutes(entity, config) {
    let routes;

    switch (config.type) {
      case OrganisationTypes.HYLYGHT:
        routes = hylyghtConfig.menu;
        if (config.homepageUrl) {
          routes.unshift(ROUTE_HOME);
        }

        if (this.hasRole(PersonEntityRoles.ROLE_ATHLETE)) {
          routes = [];
          routes.push(ROUTE_SESSIONS);
          if (process.env.REACT_APP_REHAB_ENABLED === 'true') {
            routes.push(ROUTE_EXERCISES_PROGRAMS);
          }
          routes.push(ROUTE_USER_DOCUMENTS);
        }

        if (this.hasRole(PersonEntityRoles.ROLE_REPORTER)) {
          routes = [];
          routes.push(ROUTE_SESSIONS);
          routes.push(ROUTE_SPORTERS);
        }
        break;
      case OrganisationTypes.GSV:
        routes = gsvConfig.menu;
        if (config.homepageUrl) {
          routes.unshift(ROUTE_HOME);
        }
        break;
      case OrganisationTypes.VITALE:
        routes = vitaleConfig.menu;

        break;
      default:
        routes = defaultConfig.menu;
        if (this.hasRole(PersonEntityRoles.ROLE_ATHLETE)) {
          routes = [];
          if (config.homepageUrl) {
            routes.push(ROUTE_HOME);
          }
          routes.push(ROUTE_SESSIONS);
          if (process.env.REACT_APP_REHAB_ENABLED === 'true') {
            routes.push(ROUTE_EXERCISES_PROGRAMS);
          }
          if (process.env.REACT_APP_RESULTS_ENABLED === 'true') {
            routes.push(ROUTE_RESULTS);
          }
        }
        break;
    }

    return routes;
  }

  setConfig(entity) {
    if (entity?.config) {
      const config = entity.config;

      this.personConfig = {
        menu: this.getDefaultRoutes(entity, config),
        logo: config.logo ?? defaultConfig.logo,
        name: config.name ?? defaultConfig.name,
        meta: config.meta ?? defaultMTSMetaConfig,
        type: config.type,
        benchmarksEnabled: config.benchmarksEnabled || false,
        testSetsEnabled: config.testSetsEnabled || false,
        reportTabEnabled: config.reportTabEnabled || false,
        testSetsInheritance: config.testSetsInheritance || false,
        homepageUrl: config.homepageUrl || ''
      };
    }
  }

  setRoutes(routes, abilities) {
    this.personConfig.menu = routes.filter(route => {
      if (route === ROUTE_HOME) {
        return true;
      }
      if (route) {
        return abilities.can('read', routeAbilities[route]);
      }
      return false;
    });
  }

  get config() {
    return this.personConfig;
  }

  get routes() {
    return this.config.menu;
  }

  get logo() {
    return this.config.logo;
  }

  get name() {
    return this.config.name;
  }

  get fullName() {
    return `${this.firstname.trim()} ${this.lastname.trim()}`;
  }

  get namePrefix() {
    if (!this.firstname || !this.lastname) {
      return '';
    }

    const name = `${this.firstname.trim().substring(0, 1)}${this.lastname
      .trim()
      .substring(0, 1)}`;
    return name.toUpperCase();
  }

  get homepageUrl() {
    return this.config.homepageUrl;
  }

  setRoles(roles) {
    this.entityRoles = roles;
  }

  async updateEntities(entities) {
    this.entities = entities;
  }

  static hasSubEntityInTree = (entity, subEntityId) => {
    const exist =
      entity &&
      entity.subEntities &&
      entity.subEntities.find(se => {
        if (se.id === subEntityId) {
          return true;
        }
        return Person.hasSubEntityInTree(se, subEntityId) || false;
      });
    return !!exist;
  };

  static get modelName() {
    return 'Person';
  }
}
