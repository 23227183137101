import { format, formatDistanceToNowStrict, isDate, parse } from 'date-fns';
import * as Sentry from '@sentry/react';
import differenceInYears from 'date-fns/differenceInYears';
import isValid from 'date-fns/isValid';
import fromUnixTime from 'date-fns/fromUnixTime';
import { DATE_LOCALE } from 'constants.js';
import { getLocalFromBrowser } from './locale';
import messages from 'messages';
import { capitalize } from 'utils/string';

export const getToday = () => {
  return format(new Date(), 'yyyy-MM-dd');
};

export const getLocalDateFormat = (
  dateString,
  parseFormat = 'yyyy-LL-dd',
  formatString = 'dd/LL/yyyy',
  locale = { locale: DATE_LOCALE[getLocalFromBrowser()] }
) => {
  if (!dateString) return '';
  try {
    if (!isDate(dateString) || typeof dateString === 'number') {
      const date =
        typeof dateString === 'number'
          ? fromUnixTime(dateString / 1000)
          : parse(dateString, parseFormat, new Date());
      return format(date, formatString, locale);
    }
    return format(dateString, formatString, locale);
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const getDateInYears = startdate => {
  if (!startdate) return '';
  const date = isValid(startdate)
    ? startdate
    : parse(startdate, 'yyyy-LL-dd', new Date());
  return differenceInYears(new Date(), date);
};

const daysInMonth = (m, y) => {
  switch (m) {
    case 1:
      return (y % 4 === 0 && y % 100) || y % 400 === 0 ? 29 : 28;
    case 8:
    case 3:
    case 5:
    case 10:
      return 30;
    default:
      return 31;
  }
};

export const isValidDate = (d, m, y) => {
  return m >= 0 && m < 12 && d > 0 && d <= daysInMonth(m, y);
};

export const getAgeFromDob = dob => {
  var birthday = +new Date(dob);
  return Math.round((Date.now() - birthday) / 315576000) / 100;
};

export const getAgeFromDobOnSession = (dob, date) => {
  var birthday = +new Date(dob);
  var sessionDate = +new Date(date);
  return Math.round((sessionDate - birthday) / 315576000) / 100;
};

// Parse method for Yup validation
export const parseDateString = (value, originalValue) => {
  if (!originalValue) return null;
  try {
    return isDate(originalValue)
      ? originalValue
      : parse(originalValue, 'dd/MM/YYYY', new Date());
  } catch (e) {
    return originalValue;
  }
};

export function calculateInjuriesSubtitle(injuries, locale, intl, type) {
  if (injuries?.length > 0) {
    const years = formatDistanceToNowStrict(injuries[0].injuryDate, {
      locale: DATE_LOCALE[locale]
    });
    return intl.formatMessage(messages[`subtitle${capitalize(type)}Count`], {
      count: injuries.length,
      years: years
    });
  }
  return intl.formatMessage(messages[`subtitleNo${capitalize(type)}`]);
}

export function filterDuplicateDates(dates) {
  const uniqueDates = new Set();

  return dates.filter(date => {
    const timeValue = date.getTime(); // Get the time value of the date
    if (!uniqueDates.has(timeValue)) {
      uniqueDates.add(timeValue); // Add the time value to the set
      return true; // Keep this date
    }
    return false; // Filter out this date (it's a duplicate)
  });
}
