import * as Sentry from '@sentry/react';
import * as cognito from './cognito';
import * as userQuery from './user-query';
import * as clientQuery from './client-query';
import * as appSync from './app-sync';
import { RESET_PASSWORD_URL } from 'constants.js';
import { confirmSignIn } from 'aws-amplify/auth';

/**
 * Config & connect cognito
 */
export const connect = async () => cognito.connect();

export const setClient = async () => appSync.setClient();

export const resetPassword = async email => {
  let response = { error: { message: 'Something went wrong!' } };
  try {
    const postData = { email };
    response = await fetch(RESET_PASSWORD_URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(postData)
    });
  } catch (er) {}
  return response;
};

/**
 * Login user to cognito
 * Then create AppSyncClient to get data
 */
export const login = async (credentials, token) => {
  let userData;

  try {
    const { email, ...rest } = credentials;
    const username = email.toLowerCase();
    const { isSignedIn, nextStep } = await cognito.login({
      username,
      ...rest
    });

    switch (nextStep.signInStep) {
      case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
      case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
        userData = { mfaRequired: true };
        break;
      case 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE':
        const response = await confirmSignIn({ challengeResponse: token });

        if (response.isSignedIn && response?.nextStep?.signInStep === 'DONE') {
          await cognito.getCurrentSession();
          userData = await getCurrentUser();
        }
        break;
      case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
        userData = { newPasswordRequired: true };
        break;
      case 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP':
        break;
      case 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION':
        break;
      case 'DONE':
        if (isSignedIn) {
          await cognito.getCurrentSession();
          userData = await getCurrentUser();
        }
        break;
    }
  } catch (error) {
    Sentry.captureException(error);
    userData = { error };
  }
  return userData;
};

export const loginWithTotpCode = async code => {
  let userData;
  try {
    const { isSignedIn } = await cognito.loginWithTotpCode(code);
    if (isSignedIn) {
      await cognito.getCurrentSession();
      userData = await getCurrentUser();
    }
  } catch (error) {
    Sentry.captureException(error);
    userData = { error };
  }
  return userData;
};

export const loginWithNewPassword = async password => {
  let userData;
  try {
    await cognito.completeNewPassword(password);
    userData = await getCurrentUser();
  } catch (error) {
    Sentry.captureException(error);
    userData = { error };
  }
  return userData;
};

/**
 * Get current user (when authenticated)
 */
export const getCurrentUser = async () => {
  let userData;
  try {
    await cognito.getCurrentAuthenticatedUser();
    await appSync.setClient();
    userData = await getMe();
    if (!userData.data.getMe) {
      userData = { error: { message: 'api-error' } };
    }
  } catch (error) {
    Sentry.captureException(error);
    userData = { error };
  }
  return userData;
};

export const getRootOrganisation = async () => {
  const entity = await userQuery.getRootOrganisation();
  if (entity?.data?.getRootOrganisation) {
    return entity.data.getRootOrganisation;
  }
};

/**
 * Gets my user data
 */
export const getMe = async () => userQuery.getMe();

export const getRoles = async (entityId, personId) => {
  let rolesData;
  try {
    const response = await userQuery.getRoles(entityId, personId);
    rolesData = response.data.getPersonEntityRoles;
  } catch (error) {
    console.log(error);
  }
  return rolesData;
};

export const getMeEntities = async () => {
  let entities;
  try {
    const response = await userQuery.getMeEntities();
    entities = response.data.getMe.entities;
  } catch (error) {
    entities = { error };
  }
  return entities;
};

export const getEntity = async entityId => {
  try {
    const response = await userQuery.getEntity(entityId);
    return response.data.getEntity;
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const getPersonEntity = async (personId, entityId) => {
  try {
    const response = await userQuery.getPersonEntity(personId, entityId);
    return response.data.getPersonEntity;
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const getEntityTree = async entityId => {
  try {
    const response = await clientQuery.getEntityTree(entityId);
    if (response.data?.getEntityTree) {
      return JSON.parse(response.data.getEntityTree);
    }
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const createLogin = personId => userQuery.createLogin(personId);
/**
 * Logout user
 */
export const logout = async () => {
  try {
    await cognito.logOut();
  } catch (error) {
    Sentry.captureException(error);
  }
};

/**
 * Signup
 */
// export const signup = async (email, password, lastName, firstName) => {};
