import { useContext, useEffect, useState } from 'react';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import { StoreContext } from 'index';
import ConfirmButton from 'containers/partials/buttons/ConfirmButton';
import { removePersonEntityMethod } from 'services/aws/user-query';
import useFetchAndFilterEntities from 'hooks/queries/useFetchAndFilterEntities';

const RemovePersonsFromGroupButton = ({
  entityId,
  persons,
  rootEntityId,
  children,
  onComplete,
  group,
  ...props
}) => {
  const { uiState } = useContext(StoreContext);
  const [personNames, setPersonNames] = useState('');
  const { fetchEntities } = useFetchAndFilterEntities({ entityId });

  useEffect(() => {
    if (Array.isArray(persons) && persons.length > 0) {
      const names = persons.map(person => person.fullName).join(', ');
      setPersonNames(names);
    }
  }, [persons]);

  const onConfirmRemovePerson = async persons => {
    if (Array.isArray(persons) && persons.length > 0) {
      uiState.increasePendingRequest();
      for await (const person of persons) {
        await removePersonEntityMethod({ personId: person.id, entityId });
      }
      await fetchEntities({ entityId });
      uiState.decreasePendingRequest();
      onComplete && onComplete();
    }
  };

  return (
    <ConfirmButton
      {...props}
      menu
      confirmTitle={messages.modalConfirmRemovePersonFromGroupTitle}
      confirmMessage={
        messages.modalConfirmRemovePersonFromGroupMessageWithListPersons
      }
      messageValues={{
        count: persons?.length,
        listPersons: personNames,
        group: group,
        b: chunks => <b>{chunks}</b>,
        br: () => <br />
      }}
      onOk={() => onConfirmRemovePerson(persons)}
    >
      <Icon id="delete" />
      {children}
    </ConfirmButton>
  );
};

export default RemovePersonsFromGroupButton;
