import gql from 'graphql-tag';
import { appSyncClient } from './app-sync';
// import { gqlMutation } from './utils/mutate';
import { getGQLResult } from './utils/get';

export const QUERY_GET_GROUP_BENCHMARK = gql`
  query getBenchmarkResults($entityId: ID!, $testSetId: ID!, $benchmarkId: ID) {
    getBenchmarkResults(
      entityId: $entityId
      testSetId: $testSetId
      benchmarkId: $benchmarkId
    )
  }
`;

export const QUERY_DOWNLOAD_BENCHMARK_DATA = gql`
  query printBenchmarkResults($data: AWSJSON!) {
    printBenchmarkResults(data: $data)
  }
`;

export const downloadBenchmarkData = async variables => {
  const query = QUERY_DOWNLOAD_BENCHMARK_DATA;
  return await getGQLResult(appSyncClient, query, variables);
};

export const QUERY_GET_BENCHMARKS = gql`
  query getBenchmarks($entityId: ID!) {
    getBenchmarks(entityId: $entityId) {
      id
      title
      note
      default
      draft
      originalId
      version
      type
      editable
    }
  }
`;

export const QUERY_GET_BENCHMARK = gql`
  query getBenchmark($benchmarkId: ID!, $entityId: ID) {
    getBenchmark(benchmarkId: $benchmarkId, entityId: $entityId) {
      id
      title
      note
      default
      draft
      type
      editable
      originalId
      benchmarkTestItems {
        id
        type
        testItem {
          id
          title
          hidden
        }
      }
    }
  }
`;

export const QUERY_GET_BENCHMARK_DATA = gql`
  query getBenchmark($benchmarkId: ID!, $entityId: ID) {
    getBenchmark(benchmarkId: $benchmarkId, entityId: $entityId) {
      id
      title
      default
      draft
      editable
      type
      benchmarkTestItems {
        id
        type
        data
        type
        testItem {
          id
          title
        }
      }
    }
  }
`;

export const QUERY_GET_BENCHMARKED_DATA = gql`
  query getBenchmarkedData($testDataId: ID!, $benchmarkId: ID) {
    getBenchmarkedData(testDataId: $testDataId, benchmarkId: $benchmarkId)
  }
`;

export const QUERY_EXPORT_BENCHMARKED_DATA = gql`
  query exportBenchmark($benchmarkId: ID!, $testItemId: ID!) {
    exportBenchmarkItem(benchmarkId: $benchmarkId, testItemId: $testItemId)
  }
`;

export const MUTATION_ADD_BENCHMARK = gql`
  mutation addBenchmark(
    $entityId: ID!
    $title: String
    $note: String
    $type: BenchmarkType!
  ) {
    addBenchmark(
      input: {
        entityId: $entityId
        title: $title
        note: $note
        default: false
        draft: true
        type: $type
      }
    ) {
      id
      title
      note
      default
      type
    }
  }
`;

export const MUTATION_EDIT_BENCHMARK = gql`
  mutation editBenchmark(
    $id: ID!
    $title: String!
    $note: String
    $type: BenchmarkType!
  ) {
    editBenchmark(input: { id: $id, title: $title, note: $note, type: $type }) {
      id
    }
  }
`;

export const MUTATION_PUBLISH_BENCHMARK = gql`
  mutation publishBenchmark($benchmarkId: ID!) {
    publishBenchmark(benchmarkId: $benchmarkId) {
      id
    }
  }
`;

export const MUTATION_UNPUBLISH_BENCHMARK = gql`
  mutation unpublishBenchmark($benchmarkId: ID!) {
    unpublishBenchmark(benchmarkId: $benchmarkId) {
      id
    }
  }
`;

export const MUTATION_ARCHIVE_BENCHMARK = gql`
  mutation archiveBenchmark($benchmarkId: ID!) {
    archiveBenchmark(benchmarkId: $benchmarkId)
  }
`;

export const MUTATION_COPY_BENCHMARK = gql`
  mutation addBenchmark(
    $entityId: ID!
    $title: String
    $note: String
    $originalId: ID
    $type: BenchmarkType!
  ) {
    addBenchmark(
      input: {
        entityId: $entityId
        title: $title
        note: $note
        originalId: $originalId
        default: false
        draft: true
        type: $type
      }
    ) {
      id
    }
  }
`;

export const MUTATION_EDIT_BENCHMARK_TEST_ITEM = gql`
  mutation addEditBenchmarkItem(
    $benchmarkId: ID!
    $testItemId: ID!
    $data: AWSJSON
    $type: BenchmarkSubtype
  ) {
    addEditBenchmarkItem(
      input: {
        benchmarkId: $benchmarkId
        testItemId: $testItemId
        data: $data
        type: $type
      }
    ) {
      id
      data
    }
  }
`;

export const MUTATION_RESET_BENCHMARK_DATA = gql`
  mutation deleteBenchmarkItem($benchmarkId: ID!, $testItemId: ID!) {
    deleteBenchmarkItem(benchmarkId: $benchmarkId, testItemId: $testItemId)
  }
`;
